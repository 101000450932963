<template>
  <router-link
    :to="{
      name: data.child_categories && data.child_categories.length ? 'category-main' : 'category-open',
      params: { link: data.link },
    }"
    class="search-card search-card--category"
  >
    <ImgComponent :img="data.site_square_img" class="search-card__img" width="100" height="100" contain />
    <div class="search-card__info">
      <span class="search-card__title">{{ data.title }}</span>
    </div>
    <RemixIconComponent category="system" name="arrow-right-s-line" />
  </router-link>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "SearchProductCardComponent",
  props: {
    data: Object,
  },
  components: { ImgComponent, RemixIconComponent },
};
</script>
