<template>
  <div class="default-modal delivery-modal">
    <div class="delivery-modal__header delivery-modal__header">
      <template v-if="step === 1">
        <div class="delivery-modal__wrapper">
          <div v-show="!isProfile" class="delivery-modal__tabs">
            <button
              v-for="(item, i) in tabs"
              :key="i"
              @click="changeTab(i)"
              class="btn delivery-modal__tabs-item"
              :class="{ 'delivery-modal__tabs--active': activeTab === i }"
              :aria-label="item.title"
            >
              <RemixIconComponent :category="item.category" :name="item.icon" />
              <span>{{ item.title }}</span>
            </button>
          </div>
          <!--          <button v-if="activeTab === 1" @click="isShops = true" class="btn btn&#45;&#45;outline delivery-modal__btn">-->
          <!--            Список магазинов-->
          <!--          </button>-->
          <!--          <button-->
          <!--            v-if="activeTab === 1 && isShops"-->
          <!--            @click="isShops = false"-->
          <!--            class="btn btn&#45;&#45;outline delivery-modal__btn"-->
          <!--          >-->
          <!--            Карта-->
          <!--          </button>-->
          <template v-if="activeTab === 0">
            <div v-click-outside="blur" class="delivery-modal__search">
              <InputComponent
                ref="inputSearch"
                v-model="form.search.value"
                :errors="form.search.errors"
                placeholder="Введите адрес"
                type="text"
                class="delivery-modal__input"
                @focus="focus"
              >
                <template #after>
                  <RemixIconComponent category="design" name="pencil-line" />
                </template>
              </InputComponent>
              <DadataComponent
                @setActive="setActive"
                ref="dadata"
                v-model.trim="form.search.value"
                class="delivery-modal__search-suggestions"
              />
              <div v-if="form.search.value && !premiseNumber && !focused" class="delivery-modal__search-hint">
                Необходимо указать номер дома
              </div>
            </div>
            <button
              @click="confirmAddress"
              class="btn btn--main delivery-modal__btn"
              :disabled="!premiseNumber"
              aria-label="Доставить сюда"
            >
              Доставить сюда
            </button>
            <!--            <button @click="openMyAddressModal" class="btn btn&#45;&#45;outline delivery-modal__btn">-->
            <!--              Мои адреса-->
            <!--            </button>-->
          </template>
        </div>
      </template>
      <template v-if="step === 2">
        <div class="delivery-modal__wrapper-add">
          <div class="delivery-modal-add__search">
            <InputComponent v-model="form.search.value" type="text" />
            <RemixIconComponent @click="step = 1" category="system" name="close-fill" />
          </div>
          <InputComponent
            v-model="form.entrance.value"
            :errors="form.entrance.errors"
            type="text"
            placeholder="Подъезд"
            class="delivery-modal-add__input"
          />
          <InputComponent
            v-model="form.intercom.value"
            :errors="form.intercom.errors"
            type="text"
            placeholder="Домофон"
            class="delivery-modal-add__input"
          />
          <InputComponent
            v-model="form.floor.value"
            :errors="form.floor.errors"
            type="text"
            placeholder="Этаж"
            class="delivery-modal-add__input"
          />
          <InputComponent
            v-model="form.flat.value"
            :errors="form.flat.errors"
            type="text"
            placeholder="Квартира"
            class="delivery-modal-add__input"
          />
          <button
            @click="submitAddress"
            class="btn btn--main delivery-modal__btn"
            aria-label="Сохранить адрес"
          >
            Сохранить
          </button>
        </div>
      </template>
      <button
        type="button"
        class="default-modal__close"
        @click="$emit('close')"
        aria-label="Закрыть модальное окно"
      >
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body delivery-modal__body">
      <div v-show="activeTab === 0" class="delivery-modal__map">
        <MapDeliveryComponent
          ref="deliveryMap"
          @setAddress="setAddress"
          @setPremiseNumber="setPremiseNumber"
          @setCoords="setCoords"
        />
        <!--        <MapMarkersComponent-->
        <!--          v-show="activeTab === 1"-->
        <!--          :addresses="addresses"-->
        <!--          :active="active"-->
        <!--          ref="markersMap"-->
        <!--        />-->
        <AlertComponent
          v-show="errorMessage"
          title="Ошибка"
          :description="errorMessage"
          @close="errorMessage = null"
        />
      </div>
      <ShopsComponent v-show="isShops && activeTab === 1" />
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import MapMarkersComponent from "components/MapMarkersComponent.vue";
import MapDeliveryComponent from "components/MapDeliveryComponent.vue";
import MyAddressModal from "components/modals/components/MyAddressModal.vue";
import ShopsComponent from "components/ShopsComponent.vue";
import DadataComponent from "components/DadataComponent.vue";
import ClickOutside from "vue-click-outside";
import ADDRESS_CREATE from "@/graphql/mutations/DeliveryAddressCreate.graphql";
import AlertComponent from "components/AlertComponent.vue";

export default {
  name: "DeliveryModal",
  props: {
    isProfile: Boolean,
  },
  components: {
    AlertComponent,
    DadataComponent,
    ShopsComponent,
    MapMarkersComponent,
    MapDeliveryComponent,
    InputComponent,
    RemixIconComponent,
  },
  data() {
    return {
      isShops: true,
      activeTab: 0,
      step: 1,
      premiseNumber: null,
      focused: false,
      errorMessage: null,
      form: {
        search: {
          value: null,
          errors: [],
        },
        latitude: {
          value: null,
          errors: [],
        },
        longitude: {
          value: null,
          errors: [],
        },
        entrance: {
          value: null,
          errors: [],
        },
        intercom: {
          value: null,
          errors: [],
        },
        floor: {
          value: null,
          errors: [],
        },
        flat: {
          value: null,
          errors: [],
        },
      },
      active: {
        id: 6,
        address: "улица Ермошкина, 17",
      },
      addresses: [
        {
          id: 1,
          address: "пр. Гамидова, 61",
        },
        {
          id: 6,
          address: "улица Ермошкина, 17",
        },
        {
          id: 2,
          address: "пр. Имама Шамиля, 154",
        },
        {
          id: 3,
          address: "ул. Булача, 13а",
        },
        {
          id: 4,
          address: "пр. Амет-хан Султана, 10-й км",
        },
      ],
      tabs: [
        {
          title: "Доставка",
          category: "map",
          icon: "truck-line",
        },
        {
          title: "Самовывоз",
          category: "buildings",
          icon: "store-line",
        },
      ],
    };
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
    deliveryInfo() {
      return this.$store.state.auth.client_delivery_info || {};
    },
    selectedAddress() {
      return this.$store.state.auth.client_delivery_info?.selected_address;
    },
  },
  // mounted() {
  //   неправильно работает когда идет создание адреса, а не выбор типа доставки
  //   if (this.deliveryInfo.type_id) {
  //     this.activeTab = this.deliveryInfo.type_id === this.$store.state._types.RECEIVE_DELIVERY ? 0 : 1;
  //   }
  // },
  methods: {
    confirmAddress() {
      if (this.form.search.value && this.premiseNumber) {
        this.step = 2;
      }
    },
    setAddress(address, premiseNumber) {
      this.form.search.value = address;
      this.premiseNumber = premiseNumber;
      this.errorMessage = null;
    },
    setPremiseNumber(premiseNumber) {
      this.premiseNumber = premiseNumber;
    },
    setCoords(coords) {
      this.form.latitude.value = coords[0];
      this.form.longitude.value = coords[1];
    },
    getLocation() {
      this.$refs.deliveryMap.getLocation();
    },
    setActive(address) {
      this.$refs.deliveryMap.setActive(address);
    },
    changeTab(index) {
      this.activeTab = index;
    },
    openMyAddressModal() {
      this.$store.state._modals.push({
        component: MyAddressModal,
      });
    },
    focus() {
      this.$refs.dadata.focus();
      this.focused = true;
    },
    blur() {
      this.$refs.dadata.blur();
      this.focused = false;
    },
    submitAddress() {
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: ADDRESS_CREATE,
          variables: {
            latitude: parseFloat(this.form.latitude.value),
            longitude: parseFloat(this.form.longitude.value),
            entrance: this.form.entrance.value,
            intercom: this.form.intercom.value,
            floor: this.form.floor.value,
            flat: this.form.flat.value,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          if (data.DeliveryAddressCreate) {
            location.reload();
            // this.$store.dispatch("GET_DELIVERY_INFO", {
            //   apollo: this.$apollo.provider.clients,
            //   token: this.token,
            // });
            // this.$emit("close");
          }
          this.loading = false;
        })
        .catch(({ graphQLErrors }) => {
          this.loading = false;
          this.parseGqlErrors(graphQLErrors);
        });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          if (err.extensions.validation.latitude) {
            this.errorMessage = err.extensions.validation.latitude[0];
          }
        }
      });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.delivery-modal {
  width 100%
  max-width 1096px
  border-radius 16px
  padding 32px
  gap: 32px

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    +below(800px) {
      align-items flex-start
    }

    +below(580px) {
      flex-direction column-reverse
    }
  }

  &__wrapper {
    display flex
    gap 16px
    width 100%
    flex-wrap wrap

    +below(580px) {
      flex-direction column
    }

    &-add {
      display flex
      gap 16px
      width 100%

      +below(960px) {
        display grid
        grid-template-columns 1fr 1fr
      }

      +below(580px) {
        grid-template-columns 1fr
      }
    }
  }

  &__tabs{
    border: 1px solid var(--border-color)
    border-radius: 8px;
    padding:2px
    display flex
    +below(580px) {
      width 100%
    }
    +below(340px) {
      flex-wrap wrap
    }
  }

  &__tabs--active {
    background var(--gray-800) !important

    & span {
      color var(--white)
    }

    & .icon svg {
      fill var(--white)
    }
  }

  &__tabs-item{
    height 40px
    padding: 10px 23px
    border-radius: 8px;
    +below(580px) {
      padding: 10px
      width 100%
    }

    & span {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }

    & .icon svg {
      width 16px
      height 16px
    }
  }

  &__search {
    position relative
    //width 100%
    //max-width 366px
    flex-grow 1
    border-radius: 16px;
    border 1px solid var(--border-color)
    display flex
    padding: 12px

    & .input__container {
      height auto
      border none
      padding: 0
    }

    & .input__field {
      font-size: 14px
      font-weight: 400
      padding-right: 12px
    }

    & .icon svg{
      width 20px
      height 20px
    }

    & .icon svg path {
      fill var(--black) !important
    }

    &-hint {
      position absolute
      top calc(100% + 3px)
      left 0
      right 0
      z-index 1
      background var(--white)
      border 1px solid var(--gray-900)
      padding 10px
      border-radius var(--big-radius)
    }
  }

  &__btn{
    padding: 0 12px
    height 46px
    border-radius: 16px !important;
    +below(440px) {
      width 100%
    }
  }

  &__map{
    width 100%
    .ymap-container {
      height 100%
      width 100%
    }
  }
}
.delivery-modal-add {
  &__search{
    width 100%
    max-width 280px
    border-radius: 16px;
    border 1px solid var(--border-color)
    display flex
    padding: 12px

    +below(960px) {
      max-width none
    }

    & .input__container {
      height auto
      border none
      padding: 0
    }

    & .input__field {
      font-size: 14px
      font-weight: 400
    }

    & .icon svg{
      width 20px
      height 20px
    }
  }
  &__input {
    width 120px !important

    +below(960px) {
      width 100% !important
    }
  }
}
</style>
