<template>
  <div class="default-modal cart-modal">
    <div class="cart-modal__header">
      <span class="cart-modal__title">Корзина</span>
      <div class="cart-modal__buttons">
        <button
          v-if="cartItems.length"
          @click="clearCart"
          type="button"
          class="btn btn--sm btn--gray cart-modal__clear"
        >
          <RemixIconComponent category="system" name="delete-bin-line" />
          <span>Очистить корзину</span>
        </button>
        <button
          type="button"
          class="default-modal__close"
          @click="$emit('close')"
          aria-label="Закрыть модальное окно"
        >
          <RemixIconComponent category="system" name="close-fill" />
        </button>
      </div>
    </div>
    <div class="default-modal__body cart-modal__body flex-column">
      <PriceStatusBarComponent
        v-if="minOrderPrice"
        :min-price="minOrderPrice"
        :total-price="totalPriceCart"
      />
      <template v-if="cartItems.length">
        <div class="cart-modal-product">
          <CartItemComponent
            v-for="item in cartProductItems"
            :key="'product' + item.product.id"
            :data="item"
          />
          <SetItemComponent
            v-for="(set, i) in cartSets"
            :key="'set' + set.product_set.id"
            :set="set"
            :index="i"
          />
        </div>
      </template>
      <template v-else>
        <div class="cart-modal-empty">
          <span class="cart-modal-empty__title">Пока пусто...</span>
          <span class="cart-modal-empty__subtitle">поэтому самое время найти нужный товар в каталоге и</span>
          <button @click="buyProducts" class="btn btn--main cart-modal-empty__btn" aria-label="Сделать заказ">
            Сделать заказ
          </button>
        </div>
      </template>
      <!--      <div class="cart-modal-recommended">-->
      <!--        <span class="cart-modal-recommended__title">Обрати внимание</span>-->
      <!--        <div class="cart-modal-recommended__list">-->
      <!--          <CardComponent v-for="(item, i) in [1, 2, 3, 4]" :key="i" :data="item" :grid="{}" />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="cart-modal-footer">
      <div class="cart-modal-footer__total">
        <span class="cart-modal-footer__title">Итого</span>
        <span class="cart-modal-footer__price"
          >{{ (totalProductPriceCart + totalSetPriceCart) | fixedTwo }} ₽</span
        >
        <!--        <span class="cart-modal-footer__quantity">4 товара 1 кг</span>-->
      </div>
      <button @click="orderCreate" :disabled="!cartItems.length" class="btn btn--main cart-modal-footer__btn">
        Оформить заказ
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CartItemComponent from "components/CartItemComponent.vue";
import { addSetInCart } from "../../../utils";
import SetItemComponent from "components/SetItemComponent.vue";
import CartClearModal from "components/modals/components/CartClearModal.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import PriceStatusBarComponent from "components/PriceStatusBarComponent.vue";
// import CardComponent from "components/CardComponent.vue";

export default {
  name: "CartModal",
  components: {
    PriceStatusBarComponent,
    SetItemComponent,
    CartItemComponent,
    // CardComponent,
    RemixIconComponent,
  },
  data() {
    return {
      // sliderSaleOptions: {
      //   slidesPerView: 2,
      //   grid: {
      //     rows: 2,
      //   },
      //   spaceBetween: 24,
      // },
      // sliderOptions: {
      //   slidesPerView: 5,
      //   spaceBetween: 24,
      // },
      showSetProduct: false,
      activeSet: null,
      removal: false,
    };
  },
  methods: {
    addSetInCart,
    clearCart() {
      this.$store.state._modals.push({
        component: CartClearModal,
      });
    },
    buyProducts() {
      this.$router.push({ name: "home", hash: "#shares" });
      this.$emit("close");
    },
    dropSet(i) {
      if (this.activeSet === i) {
        this.activeSet = null;
      } else this.activeSet = i;
    },
    removalCancel() {
      this.removal = false;
      clearTimeout(this.debounce);
    },
    removeSet(set) {
      this.removal = true;
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.addSetInCart({
          store: this.$store,
          apollo: this.$apollo.provider.clients.purchase,
          set: set,
          count: 0,
        });
        this.removal = false;
      }, 2500);
    },
    orderCreate() {
      if (this.isRegistered) {
        if (this.$route.name === "order") {
          this.$emit("close");
        } else {
          this.$router.push({ name: "order" });
        }
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
        });
      }
    },
  },
  computed: {
    cartItems() {
      return this.$store.state.auth.cart;
    },
    cartProductItems() {
      return this.$store.state.auth.cart.filter((item) => {
        return !item.product_set;
      });
    },
    cartSets() {
      return this.$store.state.auth.cart.filter((item) => {
        return item.product_set;
      });
    },
    totalProductPriceCart() {
      return this.$store.getters.total_product_price_cart(this.cartItems);
    },
    totalSetPriceCart() {
      return this.$store.getters.total_set_price_cart(this.cartItems);
    },
    totalPriceCart() {
      return this.totalProductPriceCart + this.totalSetPriceCart;
    },
    minOrderPrice() {
      return this.$store.state.auth.client_delivery_info &&
        this.$store.state.auth.client_delivery_info.selected_address
        ? this.$store.state.auth.client_delivery_info.selected_address.shop_zone.min_order_price
        : null;
    },
    isRegistered() {
      return this.$store.state.auth.user && this.$store.state.auth.user.is_authorized;
    },
  },
};
</script>

<style lang="stylus">
.cart-modal {
  width 100%
  max-width 1060px
  height 100%
  border-radius 0 !important
  position absolute !important
  right: 0;
  top: 0;

  +below(1220px) {
    max-width 80%
  }

  +below(480px) {
    max-width 100%
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px
    padding-bottom: 32px

    +below(580px) {
      padding: 20px
      padding-top: 40px
    }

    +below(480px) {
      padding-left: 0
      padding-right: 0
    }
  }

  &__buttons {
    margin-left auto
    display flex
    gap 16px

    & .btn {
      border-radius 12px
    }
  }

  &__title {
    font-weight: 500;
    font-size: 2em
    line-height: 120%;
    color: var(--gray-900)

    +below(480px) {
      font-size: 1.5rem
    }
  }

  &__body {
    min-height calc(100vh - 226px)
    width 100%
    overflow auto
    padding: 0 40px
    flex-grow 1

    +below(580px) {
      padding: 0 20px
    }

    +below(480px) {
      padding: 0
    }
  }

  &__status-bar {
    width 100%
    padding: 16px
    border 2px solid #5AB033
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
    display flex
    gap: 8px
    align-items center
    justify-content center
    border-radius 16px

    & span {
      font-weight: 400;
      font-size: 0.875em
      line-height: 140%;
      color: var(--green-800)
    }

    & .icon svg {
      width 20px
      height 20px
    }
  }

  //&__clear {
  //  border-radius: 12px !important;
  //}

  &-empty {
    width 100%
    height 100vh
    max-height 430px
    display flex
    flex-direction column
    justify-content center
    align-items center
    color: var(--gray-900);
    gap 8px

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-align center
    }

    &__btn {
      height 46px
      padding: 0 42px
      margin-top: 8px
      border-radius 16px !important
    }
  }

  &-recommended {
    display flex
    flex-direction column
    gap 32px
    width 100%

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: var(--gray-900);
    }

    &__list {
      display grid
      grid-template-columns 1fr 1fr
      grid-gap 24px
      width 100%
    }
  }

  &-product {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0 64px 0;
    flex-shrink 0
  }

  &-set {
    width 100%
    border: 1px solid var(--border-color);
    border-radius: 16px;
    padding: 16px
    padding-left 24px

    &__header {
      display flex
      justify-content space-between
      align-items center

      & .icon svg {
        height 16px
        width 16px
      }
    }

    &__title {
      font-weight: 500;
      font-size: 1.250em
      line-height: 24px;
      color var(--gray-900)
    }

    &__wrapper {
      display flex
      gap 21px
      align-items center
    }

    &__cancel {
      visibility hidden

      &::before {
        visibility hidden
        content: ""
        position absolute
        left 0
        z-index -1
        width 100%
        background var(--red-50)
        height 100%
        transition 5s width linear
      }
    }

    &__cancel-animated {
      visibility visible

      &::before {
        visibility visible
        width 0
      }
    }

    &__remove {
      display flex
      gap 4px
      align-items center

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color var(--gray-800)
      }
    }

    &__down {
      display flex
      align-items center
      justify-content center
      padding: 9px
      background: var(--gray-100)
      border: 1px solid var(--gray-100)
      border-radius: 12px;
    }

    &__down-reverse {
      & .icon {
        transform rotate(180deg)
      }
    }

    &__product {
      display flex
      flex-direction column
      gap 16px
      margin-top 16px;
    }
  }

  &-footer {
    width: 100%;
    position sticky
    bottom 0
    z-index 5
    background var(--white)
    border-top 1px solid var(--border-color)
    padding: 32px 40px
    display flex
    justify-content space-between
    align-items center
    min-height 90px

    +below(580px) {
      padding: 32px 20px 32px 20px
    }
    +below(420px) {
      padding 20px
    }

    &__total {
      display flex
      align-items center
      gap 8px
      color: var(--gray-900);
    }

    &__title {
      font-weight: 400;
      font-size: 1.125em
      line-height: 24px;
    }

    &__price {
      font-weight: 400;
      font-size: 1.250em
      line-height: 28px;
    }

    &__quantity {
      font-weight: 400;
      font-size: 0.875em
      line-height: 20px;
      color: var(--body_muted);
      margin-left: 8px
    }

    &__btn {
      height 50px
      padding: 0 12px
      border-radius 16px !important
      font-size: 1em
    }
  }
}
</style>
