<template>
  <div id="app" :class="{ 'app--only-page': onlyContent }">
    <HeaderComponent v-if="!onlyContent" />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent v-if="!onlyContent" />
    <div @click="hideOverlay" :class="{ 'overlay--visible': isOverlayVisible }" class="overlay"></div>
    <template v-if="!$route.query.mobile">
      <MobileBottomMenuComponent />
      <CookiesComponent />
    </template>
    <Transition name="slide">
      <MenuComponent />
    </Transition>
    <ModalComponent />
  </div>
</template>

<script>
import HeaderComponent from "components/header/index.vue";
import GLOBAL_DATA_PURCHASE from "@/graphql/queries/purchase/global_data.graphql";
import GLOBAL_DATA_LANDING from "@/graphql/queries/landing/global_data.graphql";
import GLOBAL_DATA_STORE from "@/graphql/queries/store/global_data.graphql";
import CookiesComponent from "components/CookiesComponent.vue";

export default {
  name: "App",
  components: {
    CookiesComponent,
    HeaderComponent,
    FooterComponent: () => import("components/footer/index.vue"),
    MenuComponent: () => import("components/menus/index.vue"),
    ModalComponent: () => import("components/modals/index.vue"),
    MobileBottomMenuComponent: () => import("components/MobileBottomMenuComponent.vue"),
  },
  created() {
    this.$router.afterEach(() => {
      this.hideOverlay();
    });
  },
  async mounted() {
    let promisses = [];
    promisses.push(
      this.$apollo.provider.clients.landing
        .query({
          query: GLOBAL_DATA_LANDING,
        })
        .then(({ data }) => {
          this.$store.state.global.socials = data.socials;
          this.$store.state.global.phones = data.service_phones;
          this.$store.state.global.emails = data.service_emails;
          this.$store.state.global.header_documents = data.header_documents;
          this.$store.state.global.footer_documents = data.footer_documents;
          this.$store.state.global.for_customers_documents = data.for_customers_documents;
          this.$store.state.global.company_documents = data.company_documents;
          this.$store.state.global.partners_documents = data.partners_documents;
        })
    );
    promisses.push(
      this.$apollo.provider.clients.store
        .query({
          query: GLOBAL_DATA_STORE,
          context: {
            headers: {
              Authorization: this.token ? "Bearer " + this.token : undefined,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.global.parent_categories = data.parent_categories;
        })
    );
    promisses.push(
      this.$apollo.provider.clients.purchase
        .query({
          query: GLOBAL_DATA_PURCHASE,
        })
        .then(({ data }) => {
          this.$store.state.global.shops = data.shops;
        })
    );
    await Promise.all(promisses);
  },
  computed: {
    isOverlayVisible() {
      return Object.values(this.$store.state._overlays).some((k) => k);
    },
    token() {
      return this.$store.state.auth_token;
    },
    // показывать только контент страницы, без хедера и футера
    onlyContent() {
      return this.$route.query.mobile || this.$route.meta.onlyContent;
    },
  },
  methods: {
    hideOverlay() {
      Object.keys(this.$store.state._overlays).forEach((key) => {
        this.$store.state._overlays[key] = false;
      });
    },
  },
  metaInfo: {
    titleTemplate: "%s | Зеленое яблоко",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content: "Зеленое яблоко, товары",
      },
      {
        vmid: "description",
        name: "description",
        content: "Интернет магазин  товаров.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Зеленое яблоко - интернет магазин  товаров.",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: "Интернет магазин  товаров.",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "article",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content: "https://zelenoe.comrades.dev/static/images/welcome.png",
      },
    ],
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
