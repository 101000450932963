<template>
  <div class="default-modal my-address-modal">
    <div class="default-modal__header my-address-modal__header">
      <h2>Мои адреса</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body my-address-modal__body">
      <div class="my-address-modal__items">
        <label v-for="(item, i) in items" :key="i" class="my-address-modal__item">
          <RadioComponent
            :checked="JSON.stringify(active) === JSON.stringify(item)"
            @change="handleRadioSelect(item)"
          />
          <div class="my-address-modal__item-text">
            <span class="my-address-modal__item-title">{{ item.title }}</span>
            <span class="my-address-modal__item-address">{{ item.address }}</span>
          </div>
        </label>
      </div>
      <a class="btn btn--md btn--gray my-address-modal__btn">Добавить адрес</a>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import RadioComponent from "components/inputs/RadioComponent.vue";
export default {
  name: "MyAddressModal",
  data() {
    return {
      active: undefined,
      items: [
        {
          title: "Дома",
          address: "улица Петра 1, 21к",
          active: {
            value: false,
            defaultValue: false,
            errors: [],
          },
        },
        {
          title: "Работа",
          address: "улица Яраского, 32",
          active: {
            value: false,
            defaultValue: false,
            errors: [],
          },
        },
      ],
    };
  },
  methods: {
    handleRadioSelect(item) {
      this.active = item;
    },
  },

  components: {
    RadioComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.my-address-modal {
  width 100%
  max-width 648px
  border-radius 16px
  padding 32px

  &__header {
    padding 0 0 22px 0 !important
  }

  &__body {
    padding 0 !important
    display flex
    flex-direction column
    gap 8px
  }

  &__items {
    width 100%
    display flex
    flex-direction column
    gap 8px
  }

  &__item {
    border 1px solid var(--border-color-2)
    padding 16px
    border-radius 16px
    display flex
    cursor pointer
  }

  &__item-text {
    display flex
    flex-direction column
    margin-left 8px
  }

  &__item-title {
    font-size .875em
    line-height 140%
    color var(--gray-900)
  }

  &__item-address {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__btn {
    width 100%
    height 46px
  }
}
</style>
