import Vue from "vue";
import dayjs from "dayjs";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";

Vue.filter("formatDate", (date) => {
  return dayjs(date).format("DD.MM.YYYY");
});

Vue.filter("formatDateTime", (date) => {
  return dayjs(date).format("DD.MM.YYYY HH:mm");
});

Vue.filter("humanDate", (date) => {
  require("dayjs/locale/ru");
  dayjs.locale("ru");
  return dayjs(date).format("DD MMMM YYYY");
});

Vue.filter("weekDate", (date) => {
  require("dayjs/locale/ru");
  dayjs.locale("ru");
  return dayjs(date).format("ddd, D MMM");
});

Vue.filter("robotDate", (date) => {
  return dayjs(date);
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.img) {
    return api + entity.img.url;
  }
  return "/static/images/no-image.png";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.img) {
    return entity.img.alt;
  }
  return "";
});

Vue.filter("fixedTwo", (price) => {
  if (price) {
    return Math.round(price * 100) / 100;
  }
  return 0;
});

Vue.filter("formatPrice", (price) => {
  if (price) {
    return (
      parseFloat(price).toLocaleString("ru-RU", {
        maximumFractionDigits: 1,
      }) + " ₽"
    );
  }
  return 0 + " ₽";
});
