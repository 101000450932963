<template>
  <div class="cart-item" :class="{ 'cart-item--deleted': data.quantity === 0 || !data.product.price }">
    <a @click.prevent="openProductModal" href="#" class="cart-item__wrapper-info">
      <div class="cart-item__img">
        <ImgComponent :img="data.product.img" width="180" height="180" />
      </div>
      <div class="cart-item__name">
        <span class="cart-item__title">{{ data.product.title }}</span>
        <span class="cart-item__description" v-if="pricePerQuant">
          {{ pricePerQuant }}
        </span>
      </div>
    </a>
    <div class="cart-item__wrapper-interactions">
      <button
        v-if="removal"
        @click="removalCancel"
        class="btn btn--cart-delete cart-item__remove"
        :class="{ 'cart-item__remove-animated': cancelBtnAnimated }"
        aria-label="Отмена удаления"
      >
        Отмена
      </button>
      <div v-if="!removal" class="cart-item__action">
        <template v-if="price">
          <template v-if="data.quantity !== 0">
            <span class="cart-item__price">
              <span class="cart-item__price-old" v-if="price.price_without_discount">
                {{ price.price_without_discount | fixedTwo }} ₽
              </span>
              <span
                class="cart-item__price-actual"
                :class="{ 'cart-item__price-actual--discount': price.price_without_discount }"
              >
                {{ price.value | fixedTwo }} ₽
              </span>
            </span>
            <ProductCountChangeComponent @remove="removeCartItem" :data="data.product" isCart :set="set" />
          </template>
          <template v-else>
            <button @click="returnProductInSet" class="cart-item__return-btn">Вернуть</button>
          </template>
        </template>
        <span v-else class="cart-item__not-available">Закончился</span>
        <div
          v-if="data.quantity !== 0 && data.product.price"
          @click="removeCartItem"
          class="default-modal__close cart-item__delete"
        >
          <RemixIconComponent category="system" name="close-fill" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
import { addProductInCart, addSetInCart } from "../utils";
import ProductModal from "components/modals/components/ProductModal.vue";

export default {
  name: "CartItemComponent",
  components: { ProductCountChangeComponent, RemixIconComponent, ImgComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    set: Object,
  },
  data() {
    return {
      count: 0,
      animatedInterval: null,
      removal: false,
      cancelBtnAnimated: false,
    };
  },
  mounted() {
    this.count = this.data.quantity * this.data.product.price?.step;
  },
  watch: {
    "data.quantity"(newValue) {
      this.count = newValue * this.data.product.price?.step;
    },
  },
  computed: {
    pricePerQuant() {
      if (this.data.product && this.data.product.price) {
        if (this.data.product.unit_type_id === this.types.UNIT_GRAM && this.data.product.price.price_per_kg) {
          return `${this.data.product.price.price_per_kg} ₽ за 1 кг`;
        }
        return `${this.data.product.price.value} ₽ за 1 шт`;
      }
      return null;
    },
    price() {
      if (this.data.product && this.data.product.price) {
        let count = this.count / this.data.product.price.step;
        return {
          value: count * this.data.product.price.value,
          price_without_discount: count * this.data.product.price.price_without_discount,
        };
      }
      return null;
    },
    types() {
      return this.$store.state._types;
    },
  },
  methods: {
    addProductInCart,
    addSetInCart,
    updateCount(e) {
      this.count = e;
    },
    removeCartItem() {
      this.removal = true;
      if (this.animatedInterval) {
        clearTimeout(this.animatedInterval);
      }
      this.animatedInterval = setTimeout(() => {
        this.cancelBtnAnimated = true;
      }, 100);
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        if (this.set) {
          this.addSetInCart({
            store: this.$store,
            apollo: this.$apollo.provider.clients.purchase,
            product: this.data.product,
            productSet: this.set ? this.set : undefined,
            count: 0,
          });
        } else {
          this.addProductInCart({
            store: this.$store,
            apollo: this.$apollo.provider.clients.purchase,
            product: this.data.product,
            count: 0,
          });
        }
        this.removal = false;
        clearTimeout(this.debounce);
        clearTimeout(this.animatedInterval);
      }, 2500);
    },
    removalCancel() {
      this.removal = false;
      this.cancelBtnAnimated = false;
      clearTimeout(this.debounce);
      clearTimeout(this.animatedInterval);
    },
    openProductModal() {
      if (this.data.product) {
        this.$store.state._modals = [];
        this.$store.state._modals.push({
          component: ProductModal,
          options: {
            id: this.data.product.id,
          },
        });
      }
    },
    returnProductInSet() {
      this.removal = false;
      this.cancelBtnAnimated = false;
      this.addSetInCart({
        store: this.$store,
        apollo: this.$apollo.provider.clients.purchase,
        product: this.data.product,
        productSet: this.set ? this.set : undefined,
        step: this.data.product.price.step,
        count: this.data.product.price.step,
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.cart-item{
  width 100%
  padding: 8px 12px
  display flex
  gap 8px
  align-items center
  background var(--gray-50)
  border-radius: 16px;
  height 76px
  user-select none

  +below(920px) {
    flex-direction column
    height auto
    align-items flex-start
    gap 16px
  }

  &__name{
    display flex
    flex-direction column
    gap 4px
    flex-grow 1
  }

  &__title {
    font-weight: 500;
    font-size: 1rem
    line-height: 19px;
    color var(--gray-900)
    max-width 400px
    maxlines(2)

    ^[0]--deleted & {
      opacity .5
    }

    +below(920px) {
      max-width none
    }

    +below(720px) {
      font-size: 0.875rem
    }
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-5)

    ^[0]--deleted & {
      opacity .5
    }
  }

  &__remove {
    &::before {
      content: ""
      position absolute
      left 0
      z-index -1
      width 100%
      background var(--red-50)
      height 100%
      transition 2.5s width linear
      border-radius: 0 8px 8px 0
    }
  }

  &__remove-animated {
    &::before {
      width 0
    }
  }

  &__img {
    width 60px
    height 60px
    background: var(--white)
    display flex
    border-radius: 16px;
    flex-shrink 0

    ^[0]--deleted & {
      opacity .5
    }

    & img {
      object-fit contain
      border-radius 5px
    }
  }

  &__action {
    display flex
    gap 16px
    align-items center
    +below(920px) {
      width 100%
    }
  }

  &__price {
    display flex
    flex-direction column
    justify-content flex-end
    text-align right
    font-weight: 500;
    font-size: 1.125rem
    line-height: 24px;
  }

  &__price-old {
    font-weight: 500;
    font-size: 0.625rem
    line-height: 12px;
    text-decoration-line: line-through;
    color: var(--gray-400)
  }

  &__price-actual {
    font-weight: 500;
    font-size: 1.125rem
    line-height: 24px;

    &--discount {
      color: var(--danger);
    }
  }

  &__quantity {
    padding: 13px 12px
    background: var(--white)
    border: 1px solid var(--border-color)
    border-radius: 16px;
    max-width: 120px;
    display flex

    & .input__container {
      height auto
      border none
      padding: 0
    }

    & .input__field {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--gray-900)
      text-align center

    }

    & .icon svg {
      cursor pointer
      transition var(--transition)
      width 20px
      height 20px
    }
  }

  &__wrapper-info {
    display flex
    gap 8px
    align-items center
  }

  &__wrapper-interactions {
    display flex
    gap 8px
    align-items center
    width 50%
    justify-content flex-end
    margin-left auto
    +below(920px) {
      justify-content flex-start
      width 100%
    }
  }

  &__plus:hover {
    & svg {
      fill var(--main)
    }
  }

  &__minus:hover {
    & svg {
      fill var(--red)
    }
  }

  & &__delete {
    &:hover {
      background var(--red-50)
    }
  }

  &__return-btn {
    width 140px
    height 46px
    cursor: pointer
    border-radius: 16px;
    border: 1px solid var(--gray-gray-100, #F7F7F7);
    background: var(--gray-gray-100, #F7F7F7);
    color: var(--gray-gray-800, #3F3B3B);
    font-size: 0.875rem
    font-weight: 400;
    line-height: 14px; /* 100% */
    transition var(--transition)

    &:hover {
      background var(--white)
    }
  }

  &__not-available {
    color: var(--gray-gray-900, #292929);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    padding-right: 16px
  }
}
</style>
