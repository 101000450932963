<template>
  <div class="search" :class="{ 'search--loading': loading }" v-if="searchTitle">
    <LoadingLogoComponent class="search-loading" :class="{ 'search-loading--visible': loading }" />
    <div v-if="queryTitle" class="search__inner">
      <h3 class="search__title">
        <template v-if="products.paginatorInfo && products.paginatorInfo.total">
          {{ products.paginatorInfo.total | plural_word("Найден ", "Найдено ") }}
          {{ products.paginatorInfo.total }}
          {{ products.paginatorInfo.total | plural_word("товар", "товара", "товаров") }}
          {{ ` по запросу “${queryTitle}”` }}
        </template>
        <template v-else-if="resultsCount">Результаты поиска</template>
        <template v-else>По данному запросу ничего не найдено</template>
      </h3>
      <div class="search__body flex-column" ref="scroll">
        <HotLinksComponent />
        <div v-if="categories && categories.length" class="search-block">
          <h4 class="search-block__title">Категории</h4>
          <div class="search-block__list">
            <SearchCategoryCardComponent v-for="(item, i) in categories" :key="i" :data="item" />
          </div>
        </div>
        <div v-if="products && products.data && products.data.length" class="search-block">
          <h4 class="search-block__title">Товары</h4>
          <div class="search-block__list">
            <SearchProductCardComponent v-for="(item, i) in products.data" :key="i" :data="item" />
          </div>
        </div>
        <!--        <div v-if="sets && sets.length" class="search-block">-->
        <!--          <h4 class="search-block__title">Наборы</h4>-->
        <!--          <div class="search-block__list">-->
        <!--            <SearchSetCardComponent v-for="(item, i) in sets" :key="i" :data="item" />-->
        <!--          </div>-->
        <!--        </div>-->
        <router-link
          :to="{ name: 'category-open', query: { title: queryTitle } }"
          v-if="resultsCount"
          class="btn btn--md btn--gray search--show-all"
        >
          Показать все результаты
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SearchCategoryCardComponent from "components/dropdown/SearchCategoryCardComponent.vue";
import SearchProductCardComponent from "components/dropdown/SearchProductCardComponent.vue";
import SearchSetCardComponent from "components/dropdown/SearchSetCardComponent.vue";
import HotLinksComponent from "components/HotLinksComponent.vue";
import IconComponent from "components/IconComponent.vue";
import SEARCH from "@/graphql/queries/store/search_query.graphql";
import { checkScroll } from "@/utils";
import LoadingLogoComponent from "components/LoadingLogoComponent.vue";

export default {
  name: "SearchComponent",
  inject: ["title"],
  data() {
    return {
      searchTitle: this.title,
      queryTitle: null, // заголовок по которому уже сделали запрос
      loading: false,
      products: {},
      categories: [],
      sets: [],
    };
  },
  mounted() {
    this.checkScroll();
  },
  watch: {
    title(val) {
      this.searchTitle = val; // костыль, т.к. обращение к title выдает ошибку
      if (val) {
        if (this.inputDebounceTimeout) {
          clearTimeout(this.inputDebounceTimeout);
        }
        this.loading = true;
        this.inputDebounceTimeout = setTimeout(() => {
          this.load();
        }, 500);
      } else {
        this.products = {};
        this.searchTitle = null;
        this.queryTitle = null;
      }
    },
  },
  methods: {
    checkScroll,
    async load() {
      let token = this.$store.state.auth_token;
      await this.$apolloProvider.clients.store
        .query({
          query: SEARCH,
          variables: {
            categories_first: 3,
            products_first: 6,
            sets_first: 2,
            title: this.title || undefined,
          },
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          this.queryTitle = this.title;
          this.products = data.products_paginate;
          this.categories = data.categories?.data || [];
          this.sets = data.product_sets_paginate?.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    // количество всех результатов поиска (товаров, категорий и наборов)
    resultsCount() {
      return (this.products?.data?.length || 0) + this.categories.length + this.sets.length;
    },
  },
  components: {
    LoadingLogoComponent,
    IconComponent,
    HotLinksComponent,
    SearchCategoryCardComponent,
    SearchProductCardComponent,
    SearchSetCardComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.search {
  position relative
  padding 32px 40px
  min-height 100px
  max-height calc(100vh - 200px)
  display flex
  flex-direction column
  +below(768px) {
    padding 20px 30px
  }
  +below(480px) {
    padding 20px
  }

  &--loading &__inner {
    opacity 0.6
  }

  &__inner {
    height 100%
    max-height 100%
    gap 24px
    display flex
    flex-direction column
  }

  &-loading {
    absolute top 10% left right
    width 80px
    height 80px
    margin auto
		z-index: 2
		pointer-events: none
		opacity: 0
		transition: opacity var(--transition)
    +below(480px) {
      width 60px
      height 60px
    }

		&--visible {
			pointer-events: auto
			opacity: 1
		}

    svg circle {
      stroke var(--gray-900)
    }
  }

  &__title {
    font-size 1.5em
    font-weight 500
    line-height: 36px;
    margin 0
    flex-shrink 0
    +below(540px) {
      font-size 1.125em
      line-height 1.5
    }
  }

  &__subtitle {
    font-size 1.25em
    font-weight 500
    line-height 120%
  }

  &__body {
    display flex
    flex-direction column
    gap 16px
    overflow auto

    &.scroll {
      padding-right 10px
    }
  }

  &-block {
    display flex
    flex-direction column
    gap 16px

    &__list {
      display flex
      flex-direction column
      gap 8px
    }

    &__title {
      margin 0
      font-weight: 500;
      font-size: 1.25em
      line-height: 32px;
    }
  }

  &--show-all {
    flex-shrink 0
  }

  &-card {
		position: relative
    height 76px
    display flex
    align-items center
    gap 8px
    padding 7px 11px
    border 1px solid var(--border-color-2)
    border-radius 16px
    transition var(--transition)
    +below(540px) {
      height 60px
      padding 5px 10px
    }

    &:hover {
      box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    }

		&__link {
			position: absolute
			left: 0
			right: 0
			top: 0
			bottom: 0
			z-index: 1
		}

		&--product& {
			+below(540px) {
				display: grid
				grid-template-columns: 60px 1fr
				gap: 4px 8px
				height: auto
			}

			.product-count {
				+below(540px) {
					max-width: 100%
				}
			}
		}

    &--category &__title {
      font-weight 400
    }

    &__img {
      width 60px
      height 60px
      flex-shrink 0
      border-radius 16px
      +below(540px) {
        width 50px
        height 60px
      }
    }

    &__info {
      display flex
      flex-direction column
      flex-grow 1
    }

    &__title {
      font-weight 500
      color var(--body-color)
      maxlines(2)
      +below(540px) {
        font-size 0.875em
        line-height 1.5
      }
    }

    &__subtitle {
      font-size: 0.75em
      line-height: 16px;
      color: var(--gray-5);
    }

    .btn {
      padding 10px
      width 40px
      height: 38px
      flex-shrink 0
    }

    .icon {
      width: 20px
      height: 20px
    }

		& &__count-change {
			z-index: 2
			+below(540px) {
				grid-column: span 2
				width: 100%
				max-width: 100%
			}
		}
  }
}
</style>
