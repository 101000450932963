<template>
  <div class="search-card search-card--product">
    <a class="search-card__link" @click.prevent="openProductModal" href="#"></a>
    <ImgComponent :img="data.img" class="search-card__img" width="100" height="100" contain />
    <div class="search-card__info">
      <span class="search-card__title">{{ data.title }}</span>
    </div>
    <ProductCountChangeComponent class="search-card__count-change" :data="data" />
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import ProductModal from "components/modals/components/ProductModal.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";

export default {
  name: "SearchProductCardComponent",
  props: {
    data: Object,
  },
  methods: {
    openProductModal() {
      this.$store.state._modals = [];
      this.$store.state._modals.push({
        component: ProductModal,
        options: {
          id: this.data.id,
        },
      });
    },
  },
  components: { ProductCountChangeComponent, ImgComponent, RemixIconComponent },
};
</script>
