import me from "gql/auth/me.graphql";
import user_data from "gql/auth/user_data.graphql";
import GET_CART from "@/graphql/queries/purchase/cart_items.graphql";
import POINTS from "gql/auth/points.graphql";
import my_addresses from "@/graphql/queries/purchase/my_addresses.graphql";
import my_cards from "@/graphql/queries/purchase/my_cards.graphql";
import my_orders from "@/graphql/queries/purchase/my_orders_paginate.graphql";
import client_delivery_info from "@/graphql/queries/purchase/client_delivery_info.graphql";
import sync_guest_cart from "@/graphql/mutations/SyncGuestCart.graphql";
import sync_guest_address from "@/graphql/mutations/SyncGuestDeliveryAddress.graphql";
import cart_delete from "gql/mutations/purchase/CartDelete.graphql";

async function USER({ state }, { apollo }) {
  let token = state.auth_token;
  let user = state.auth.user;
  await apollo.lk
    .query({
      query: me,
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.user = data.me;
    });
  await apollo.purchase
    .query({
      query: user_data,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.cart = data.cart_items;
      state.auth.my_cards = data.my_cards;
      state.auth.my_addresses = data.my_addresses;
      state.auth.client_delivery_info = data.client_delivery_info;
    });
  if (user && user.is_authorized) {
    await apollo.loyal
      .query({
        query: POINTS,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        state.auth.points = data.points;
      });
  }
}

async function GET_CART_ITEMS({ state }, { apollo }) {
  state.cart_loading = true;
  let token = state.auth_token;
  await apollo.purchase
    .query({
      query: GET_CART,
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.cart = data.cart_items;
    })
    .finally(() => {
      state.cart_loading = false;
    });
}

async function GET_ADDRESSES({ state }, { apollo }) {
  await apollo.purchase
    .query({
      query: my_addresses,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + state.auth_token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.my_addresses = data.my_addresses;
    });
}

async function GET_CARDS({ state }, { apollo }) {
  await apollo.purchase
    .query({
      query: my_cards,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + state.auth_token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.my_cards = data.my_cards;
    });
}

async function GET_ORDERS({ state }, { apollo }) {
  await apollo.purchase
    .query({
      query: my_orders,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + state.auth_token,
        },
      },
    })
    .then(({ data }) => {
      state.auth.my_orders = data.my_orders;
    });
}

async function GET_DELIVERY_INFO({ state }, { apollo }) {
  let userToken = state.auth_token;
  await apollo.purchase
    .query({
      query: client_delivery_info,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      },
    })
    .then(({ data }) => {
      state.auth.client_delivery_info = data.client_delivery_info;
    });
}

async function SYNC_USER_DATA({ state }, { apollo, user }) {
  let token = state.auth_token;
  let syncCart = await apollo.purchase.mutate({
    mutation: sync_guest_cart,
    variables: {
      id: user?.id,
    },
    context: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });
  let syncAddress = await apollo.purchase.mutate({
    mutation: sync_guest_address,
    variables: {
      id: user?.id,
    },
    context: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });
  return Promise.all([syncCart, syncAddress]);
}

async function CART_DELETE({ state }, { apollo }) {
  let token = state.auth_token;
  apollo
    .mutate({
      mutation: cart_delete,
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(() => {
      state.auth.cart = [];
    })
    .catch((e) => {
      console.log("error", e);
    });
}

export default Object.assign({
  USER,
  GET_CART_ITEMS,
  GET_ADDRESSES,
  GET_DELIVERY_INFO,
  SYNC_USER_DATA,
  GET_CARDS,
  GET_ORDERS,
  CART_DELETE,
});
