<template>
  <div class="stars-small" v-if="avgStars">
    <RemixIconComponent
      category="system"
      name="star-fill"
      v-for="(item, i) in 5"
      :key="item"
      :class="{ 'stars-small--fill': i < roundedStars }"
    />
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "StarsSmallComponent",
  props: {
    selectStars: {
      type: Number,
      default: 0,
    },
    avgStars: {
      type: Number,
    },
  },
  computed: {
    roundedStars() {
      return Math.round(this.avgStars);
    },
  },
  components: {
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.stars-small {
  .icon {
    width 24px
    height 24px
  }

  .icon svg path {
    fill var(--gray-100)
  }

  &--fill {
    svg path {
      fill var(--yelow-500) !important
    }
  }
}
</style>
