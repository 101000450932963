<template>
  <div class="default-menu catalog-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title">Каталог</span>
        <button type="button" class="default-menu__close" @click="$emit('close')" aria-label="Закрыть меню">
          <RemixIconComponent category="system" name="close-fill" />
        </button>
      </div>
    </div>
    <div class="default-menu__body catalog-menu__body">
      <ul class="catalog-menu__categories">
        <li v-for="(item, i) in categories" :key="i">
          <router-link
            :to="{ name: 'category-main', params: { link: item.link } }"
            :class="{ 'catalog-menu__category--active': active === item.id }"
            class="catalog-menu__category"
          >
            <ImgComponent :img="item.site_catalog_img" class="catalog-menu__category-img" />
            <span>{{ item.title }}</span>
            <button
              v-if="item.child_categories && item.child_categories.length"
              @click.prevent.stop="setActive(item.id, 'active')"
              class="btn"
              :aria-label="item.title"
            >
              <RemixIconComponent category="system" name="arrow-drop-down-line" />
            </button>
          </router-link>
          <ul
            :ref="'categoryChilds' + item.id"
            class="catalog-menu__category-childs"
            v-if="item.child_categories && item.child_categories.length"
          >
            <li v-for="(child, index) in item.child_categories" :key="index">
              <router-link
                :to="{ name: 'category-open', params: { link: child.link } }"
                :class="{ 'catalog-menu__category--active': activeChild === child.id }"
                class="catalog-menu__category"
              >
                <ImgComponent :img="child.site_catalog_img" class="catalog-menu__category-img" />
                <span>{{ child.title }}</span>
                <!--                <button-->
                <!--                  v-if="child.child_categories && child.child_categories.length"-->
                <!--                  @click.prevent="setActive(child.id, 'activeChild')"-->
                <!--                  class="btn"-->
                <!--                >-->
                <!--                  >-->
                <!--                </button>-->
              </router-link>
              <!--              <ul-->
              <!--                :ref="'categoryChilds' + child.id"-->
              <!--                class="catalog-menu__category-childs"-->
              <!--                v-if="child.child_categories"-->
              <!--              >-->
              <!--                <li v-for="(subchild, k) in child.child_categories" :key="k">-->
              <!--                  <router-link-->
              <!--                    :to="{ name: 'category-open', params: { link: subchild.link } }"-->
              <!--                    :class="{ 'catalog-menu__category&#45;&#45;active': $route.params.id === subchild.id }"-->
              <!--                    class="catalog-menu__category"-->
              <!--                  >-->
              <!--                    <span>{{ subchild.title }}</span>-->
              <!--                    <button v-if="subchild.child_categories && subchild.child_categories.length" class="btn">-->
              <!--                      <RemixIconComponent category="system" name="arrow-drop-down-line" />-->
              <!--                    </button>-->
              <!--                  </router-link>-->
              <!--                </li>-->
              <!--              </ul>-->
            </li>
          </ul>
        </li>
      </ul>
      <div class="catalog-menu__bottom">
        <div class="container-padding">
          <router-link class="btn btn--md btn--main" :to="{ name: 'catalog' }"
            >Перейти на каталог</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CatalogMenu",
  data() {
    return {
      active: undefined,
      activeChild: undefined,
    };
  },
  computed: {
    categories() {
      return this.$store.getters.parent_categories;
    },
  },
  methods: {
    setActive(id, active) {
      let el = this.$refs[`categoryChilds${id}`][0];
      if (id !== this[active]) {
        if (this[active]) {
          this.$refs[`categoryChilds${this[active]}`][0].style.height = 0; // закрываем предыдущий
        }
        this[active] = id;
        el.style.height = el.scrollHeight + "px";
      } else {
        this[active] = undefined;
        el.style.height = 0;
      }
    },
  },
  components: { ImgComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.catalog-menu {
  position relative

  & &__body {
    padding-bottom 60px
  }

  &__categories {
    display grid
    //max-height 572px
    overflow-y auto

    > li {
      display grid

      &:not(:last-child) {
        border-bottom 1px solid var(--gray)
      }
    }
  }

  &__category {
    padding 5px 0
    font-size: 0.875em
    line-height: 20px;
    gap 10px
    display flex
    align-items center
    position relative
    color var(--body-color)

    &:hover {
      color var(--main)
    }

    &--active {
      color: var(--main);

      .catalog-menu__category-img {
        border-color var(--green);
      }

      .icon {
        transform rotate(-180deg)
      }
    }

    &-img {
      width 40px
      height: 40px
      border-radius 50%
      overflow hidden
      border 2px solid transparent
    }

    .icon {
      width 12px
      height 12px
      transition var(--transition)
    }

    > .btn {
      height 50px
      width 50px
      position absolute
      right 0
      top 0
      bottom 0
    }

    &-childs {
      padding-left 15px
      height 0
      min-height 0
      overflow hidden
      transition var(--transition)

      .catalog-menu__category-img {
        width 30px
        height 30px
        border none
      }
    }
  }

  &__bottom {
    absolute bottom left right
    width 100%
    padding-bottom 10px

    .btn {
      width 100%
    }
  }
}
</style>
