<template>
  <div
    ref="header-main"
    id="header-main"
    class="header-main"
    :class="{
      'header-main__scroll': scrollingPage,
      'header-main--search': overlays.searchOverlay,
    }"
  >
    <div class="container">
      <div class="header-main__content">
        <div class="header-main__left">
          <router-link :to="{ name: 'home' }" class="header-main__logo">
            <img class="header-main__logo-img" src="/static/svg/logo.svg" alt="logo" />
            <img
              class="header-main__logo-img header-main__logo-img--mobile"
              src="/static/svg/logo_mobile.svg"
              alt="logo"
            />
          </router-link>
          <button
            @click="toggleCatalog"
            class="btn btn--sm header-main__catalog"
            :class="{ 'btn--green-light': overlays.catalogOverlay, 'btn--main': !overlays.catalogOverlay }"
          >
            <RemixIconComponent
              category="system"
              :name="overlays.catalogOverlay ? 'close-line' : 'apps-2-line'"
            />
            <span class="header-main__catalog-title">Каталог</span>
          </button>
        </div>
        <div class="header-main__right">
          <label class="header-main__search">
            <RemixIconComponent category="system" name="search-line" />
            <InputComponent
              v-model.trim="title"
              @focus="changeOverlay({ store: $store, field: 'searchOverlay', value: true })"
              type="text"
              placeholder="Поиск по сайту"
              ref="search"
            />
          </label>
          <button
            @click="changeOverlay({ store: $store, field: 'searchOverlay' })"
            class="header-main__search header-main__search--mobile"
          >
            <RemixIconComponent
              category="system"
              :name="overlays.searchOverlay ? 'close-fill' : 'search-line'"
            />
          </button>
          <button
            @click.prevent="openDeliveryModal"
            class="header-main__geo btn"
            aria-label="Выбрать адрес доставки или самовывоза"
          >
            <RemixIconComponent category="map" name="map-pin-2-line" />
            <span class="header-main__geo-wrapper">
              <span class="header-main__geo-title">
                <span v-if="client_delivery_info.type_id">
                  {{ client_delivery_info.type_id === 1 ? "Самовывоз" : "Доставка" }}:
                </span>
                <span v-else>Доставка:</span>
              </span>
              <span class="header-main__geo-subtitle">
                <span
                  v-if="
                    client_delivery_info.selected_address && client_delivery_info.selected_address.address
                  "
                >
                  {{ client_delivery_info.selected_address.address }}
                </span>
                <span
                  v-else-if="
                    client_delivery_info.shop &&
                    (client_delivery_info.shop.address || client_delivery_info.shop.title)
                  "
                >
                  {{ client_delivery_info.shop.address || client_delivery_info.shop.title }}
                </span>
                <span v-else>Выберите способ получения</span>
              </span>
            </span>
            <RemixIconComponent category="design" name="pencil-line" class="header-main__geo-icon" />
          </button>
          <router-link :to="{ name: 'sets' }" class="btn btn--gray header-main__sets">
            <RemixIconComponent category="health" name="heart-3-line" />
            <span class="header-main__sets-title">Наборы</span>
          </router-link>
          <div v-if="cartItems.length" @click="openCartModal" class="btn btn--cart header-main__cart">
            <RemixIconComponent category="finance" name="shopping-cart-2-fill" />
            <span>{{ (totalProductPriceCart + totalSetPriceCart) | formatPrice }}</span>
            <div class="header-main__cart-quantity">
              <span>{{ totalCountCart }}</span>
            </div>
          </div>
          <button v-else @click="openCartModal" class="btn btn--gray btn--md header-main__cart">
            <RemixIconComponent category="finance" name="shopping-cart-line" />
            <span class="header-main__cart-title">Корзина</span>
          </button>
          <router-link
            :to="{ name: 'profile' }"
            class="btn header-main__profile"
            :class="{ 'btn--white--outline': isRegistered, 'btn--gray': !isRegistered }"
          >
            <span v-show="isRegistered && user.name" class="header-main__user-info">
              <span class="header-main__user-name">{{ user.name }}</span>
              <!--              <span>3514 б.</span>-->
            </span>
            <RemixIconComponent category="user" :name="isRegistered ? 'user-follow-line' : 'user-line'" />
          </router-link>
          <DropdownComponent :title="title" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import DeliveryModal from "components/modals/components/DeliveryModal.vue";
import cartModal from "components/modals/components/CartModal.vue";
import DropdownComponent from "components/dropdown/DropdownComponent.vue";
import CatalogMenu from "components/menus/components/CatalogMenu.vue";
import { changeOverlay } from "@/utils";
import { computed } from "vue";

export default {
  name: "HeaderMainComponent",
  provide() {
    return {
      title: computed(() => this.title),
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Number,
      default: 0,
    },
    openSearch: {
      type: Boolean,
      default: false,
    },
    scrollingPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      headerScroll: null,
      title: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {};
    },
    client_delivery_info() {
      return this.$store.state.auth.client_delivery_info || {};
    },
    isRegistered() {
      return !!(this.user && this.user.is_authorized);
    },
    overlays() {
      return this.$store.state._overlays;
    },
    cartItems() {
      return this.$store.state.auth.cart || [];
    },
    totalProductPriceCart() {
      return this.$store.getters.total_product_price_cart(this.cartItems);
    },
    totalSetPriceCart() {
      return this.$store.getters.total_set_price_cart(this.cartItems);
    },
    totalCountCart() {
      return this.cartItems ? this.cartItems.length : null;
    },
  },
  methods: {
    changeOverlay,
    toggleCatalog() {
      if (window.innerWidth >= 1026) {
        this.changeOverlay({ store: this.$store, field: "catalogOverlay" });
      } else {
        this.$store.state._menus.push({
          component: CatalogMenu,
          options: {},
        });
      }
    },
    openDeliveryModal() {
      this.$store.state._modals.push({
        component: DeliveryModal,
        options: {},
      });
    },
    openCartModal() {
      this.$store.state._menus.push({
        component: cartModal,
        options: {},
        alignRight: true,
      });
    },
  },
  components: { DropdownComponent, RemixIconComponent, InputComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.header-main {
  background var(--white)
  width 100%
  padding: 0 16px
  z-index 3

  &__scroll {
    top: 0;
    position fixed
  }

  &__user-info {
    display flex
    flex-direction column
    gap 4px
    color: #4B4B4C;
    text-align: right;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 12px;
  }

  &__user-name {
    font-size: 0.875rem
    font-weight: 400;
    line-height: 14px;
  }

  &--search {
    +below(440px) {
      & ^[0]__left {
        display none
      }

      & ^[0]__geo.btn {
        display none
      }

      & ^[0]__search {
        &:not(&--mobile) {
          display flex
        }

        &--mobile {
          display flex
        }
      }

			& ^[0]__sets {
				display none
			}
    }
  }

  &__content {
    position relative
    padding: 16px 0
    display flex
    gap 16px
    +below(540px) {
      gap: 8px
    }
  }

  &__left,
  &__right {
    display flex
    gap 16px
    justify-content space-between
    +below(540px) {
      gap: 8px
    }
  }

  &__left {
    flex-shrink 0
  }

  &__right {
    flex-grow 1
    justify-content flex-end
  }

  &__logo {
    display flex
    width 100%
    max-width 209px
    max-height 26px
    align-self center
    +below(1200px) {
      width 50px
      max-height 30px
      flex-shrink 0
    }

    &-img {
      max-height 26px

      &--mobile {
        +above(1201px) {
          display none
        }
      }

      &:not(&--mobile) {
        +below(1200px) {
          display none
        }
      }
    }

    & .icon {
      max-width 209px
    }
  }

  & &__catalog {
    flex-shrink: 0
    font-weight: 400;
    font-size: 0.875em
    line-height: 140%
    height 46px
    padding: 0 20px
    border-radius: 16px
    +below(990px) {
      padding: 0 20px
      flex-shrink 0
    }
    +below(768px) {
      display none
    }

    .icon {
      width 20px
      height 20px
    }

    &-title {
      +below(990px) {
        display none
      }
    }
  }

  &__cart-quantity {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--main);
    display flex
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.75rem
    line-height: 13px;
    color: var(--white)
  }

  &__cart.btn {
    font-weight: 400;
    line-height: 20px
    height 46px
    font-size: 0.875rem
    gap 4px
    +below(990px) {
      padding 12px
      flex-shrink 0
    }
    +below(768px) {
      display none
    }

    .icon {
      width 20px
      height 20px
    }
  }

  &__cart-title {
    +below(990px) {
      display none
    }
  }

  &__profile.btn {
    flex-shrink 0
    +below(768px) {
      display none
    }
  }

  &__search {
    flex-grow 3
    border: 1px solid var(--border-color-2)
    background: var(--white);
    border-radius: 16px;
    padding: 12px;
    display: flex;
    gap: 5px;
    transition border-color var(--transition)

    &:hover {
      border-color var(--main)
    }

    //&:not(&--mobile) {
    //  +below(440px) {
    //    display none
    //  }
    //}

    &--mobile {
      display none
      flex-grow 0
      flex-shrink 0
      cursor pointer
      +above(441px) {
        display none
      }
    }

    .input__container {
      height auto
      border none
      padding 0
    }

    .icon {
      height 20px
      width 20px
      fill var(--gray-900)
    }
  }

  &__geo.btn {
    display flex
    gap 2px
    border 1px solid var(--border-color-2)
    padding: 7px 12px
    padding-left: 5px;
    justify-content space-between
    width 254px
    border-radius: 16px
    +below(990px) {
      width auto
      padding: 7px 12px
      flex-shrink 0
    }

    &:hover {
      border-color var(--main)
    }

    .icon svg path {
      fill var(--gray-900) !important
    }
  }

  &__geo-icon{
    +below(990px) {
      display none
    }
  }

  &__geo-wrapper {
    display flex
    flex-direction column
    color var(--gray-900) !important
    text-align start
    width 100%
    +below(990px) {
      display none
    }
  }

  &__geo-title {
    font-weight: 600;
    font-size: 12px
    line-height: 17px;
  }

  &__geo-subtitle {
    font-weight: 400;
    font-size: 12px
    line-height: 14px
    maxlines(1)
  }

	&__sets {
		flex-shrink 0

		&-title {
			+below(990px) {
				display none
			}
		}
	}

  &__btn {
    background var(gray-100)
    padding: 12px !important

    .icon svg path {
      fill var(--gray-800) !important
    }
  }
}
</style>
