import Vue from "vue";
import VueApollo from "vue-apollo";
import fetch from "node-fetch";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

Vue.use(VueApollo);

export default function createApollo(env) {
  const host = env.HOST_ENDPOINT;
  const cache = new InMemoryCache();

  const landingClient = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-landing",
      fetch: fetch,
    }),
    cache,
  });
  const cabinetClient = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-lk",
      fetch: fetch,
    }),
    cache,
  });
  const storeClient = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-store",
      fetch: fetch,
    }),
    cache,
  });
  const purchaseClient = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-purchase",
      fetch: fetch,
    }),
    cache,
  });
  const adminClient = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-admin",
      fetch: fetch,
    }),
    cache,
  });
  const loyalOnline = new ApolloClient({
    link: createUploadLink({
      uri: host + "/graphql-loyal-online",
      fetch: fetch,
    }),
    cache,
  });

  return new VueApollo({
    clients: {
      landing: landingClient,
      lk: cabinetClient,
      store: storeClient,
      purchase: purchaseClient,
      admin: adminClient,
      loyal: loyalOnline,
    },
    defaultClient: landingClient,
  });
}
