import "es6-promise/auto";
import createApp from "./app";
import ProductModal from "components/modals/components/ProductModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import GUEST_TOKEN from "@/graphql/auth/GenerateGuestToken.graphql";
import USER_DATA from "gql/auth/user_data.graphql";
import POINTS from "gql/auth/points.graphql";
import ME from "gql/auth/me.graphql";
import { setCookie, getCookie } from "./utils";
import OrderInfoModal from "components/modals/components/OrderInfoModal.vue";

const { app, router, store, apollo } = createApp(window.__INITIAL_STATE__._env);

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
  store.state._window_size = window.innerWidth;
  store.state._env = window.__INITIAL_STATE__._env || {};
  store.state.auth_token = window.__INITIAL_STATE__.auth_token || getCookie("auth-token") || undefined;
}

router.onReady(async () => {
  let token = store.state.auth_token;
  let user = null;
  if (token) {
    await apollo.clients.lk
      .query({
        query: ME,
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        if (data.me) {
          // Авторизация успешна сохраняем пользователя
          store.state.auth.user = data.me;
          user = data.me;
        } else {
          store.state._modals.push({
            component: LoginModal,
          });
        }
      })
      .catch(() => {
        store.state._modals.push({
          component: LoginModal,
        });
      });
    apollo.clients.purchase
      .query({
        query: USER_DATA,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.auth.cart = data.cart_items;
        store.state.auth.client_delivery_info = data.client_delivery_info;
        store.state.auth.my_cards = data.my_cards;
        store.state.auth.my_addresses = data.my_addresses;
      });
    if (user && user.is_authorized) {
      apollo.clients.loyal
        .query({
          query: POINTS,
          fetchPolicy: "no-cache",
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          store.state.auth.points = data.points;
        });
    }
  } else {
    apollo.clients.lk
      .mutate({
        mutation: GUEST_TOKEN,
      })
      .then(({ data }) => {
        if (data.GenerateGuestToken) {
          store.state.auth_token = data.GenerateGuestToken;
          let fd = {
            token: data.GenerateGuestToken,
          };
          fetch("/guest-token", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(fd),
          });
          setCookie("auth-token", data.GenerateGuestToken, 999);
          store.dispatch("USER", {
            apollo: apollo.clients,
          });
        }
      });
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("no_auth") === "1") {
    store.state._modals.push({
      component: LoginModal,
    });
    urlParams.delete("no_auth");
    history.replaceState(null, "", location.pathname + urlParams);
  }
  if (urlParams.get("success_email")) {
    router.push({ name: "profile" });
  }
  if (urlParams.get("success") === "1") {
    router.push({ name: "order" });
    urlParams.delete("success");
    history.replaceState(null, "", location.pathname + urlParams);
  }
  if (urlParams.get("product_id")) {
    store.state._modals.push({
      component: ProductModal,
      options: {
        id: parseInt(urlParams.get("product_id")),
        token: urlParams.get("token"),
      },
      callback: () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("product_id");
        urlParams.delete("token");
        history.replaceState(null, "", location.pathname + urlParams);
      },
    });
  }
  if (urlParams.get("order_modal")) {
    store.state._modals.push({
      component: OrderInfoModal,
      options: {
        id: parseInt(urlParams.get("order_modal")),
      },
      callback: () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("order_modal");
        history.replaceState(null, "", location.pathname + urlParams);
      },
    });
  }
  router.beforeEach((to, from, next) => {
    if (to.meta.authRequired && !store.state.auth.user?.is_authorized) {
      store.state._modals.push({
        component: LoginModal,
      });
      next(false);
    } else {
      next();
    }
  });
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const asyncDataHooks = matched.map((c) => c.asyncData).filter((_) => _);
    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to, apollo })))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

if (location.protocol === "https:" && navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js").then((value) => {
    console.log(value);
  });
}
