import Vue from "vue";
import * as filters from "./filters.js";

import App from "./App.vue";
import createStore from "./store";
import createRouter from "./router.js";
import createApollo from "./apollo.js";

import Meta from "vue-meta";
import Cookie from "vue-cookie";
import VueMask from "v-mask/dist/v-mask.min.js";

Vue.use(Meta);
Vue.use(Cookie);
Vue.use(VueMask);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

export default function createApp(env) {
  const store = createStore();
  const router = createRouter();
  const apolloProvider = createApollo(env);
  const app = new Vue({
    router,
    store,
    apolloProvider,
    render: (h) => h(App),
  });

  return { app, router, store, apollo: apolloProvider };
}
