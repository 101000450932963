import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
      meta: {
        showHotLinks: true,
      },
    },
    {
      path: "/sets",
      name: "sets",
      component: () => import("./views/sets/index.vue"),
      meta: {
        showHotLinks: false,
      },
    },
    {
      path: "/sets-group/:id?/:link?",
      name: "sets-group",
      component: () => import("./views/sets/group.vue"),
      meta: {
        showHotLinks: false,
      },
    },
    {
      path: "/sets-item/:id",
      name: "sets-item",
      component: () => import("./views/sets/open.vue"),
      meta: {
        showHotLinks: false,
      },
    },
    // {
    //   path: "/sets-settings",
    //   name: "sets-settings",
    //   component: () => import("./views/sets/settings.vue"),
    //   meta: {
    //     showHotLinks: false,
    //   },
    // },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/about/index.vue"),
      meta: {
        showHotLinks: false,
      },
    },
    {
      path: "/order",
      name: "order",
      component: () => import("./views/order/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./views/documents/index.vue"),
      meta: {
        showHotLinks: false,
      },
    },
    {
      path: "/profile/:link?",
      name: "profile",
      component: () => import("./views/profile/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/catalog",
      name: "catalog",
      component: () => import("./views/catalog/index.vue"),
    },
    {
      path: "/category-main/:link",
      name: "category-main",
      component: () => import("./views/catalog/main.vue"),
    },
    {
      path: "/category-open/:link?",
      name: "category-open",
      component: () => import("./views/catalog/open.vue"),
    },
    {
      path: "/filters",
      name: "filters",
      component: () => import("./views/catalog/open.vue"),
      meta: {
        filters: true,
      },
    },
    {
      path: "/static/:id?",
      name: "static",
      component: () => import("./views/static/index.vue"),
    },
    {
      path: "/oauth",
      name: "oauth",
      redirect: "/",
      component: () => import("./views/OauthPage.vue"),
      meta: {
        onlyContent: true,
      },
    },
    {
      path: "/delete-profile-file",
      name: "delete-profile-file",
      component: () => import("./views/static/DeleteProfileInfo.vue"),
    },
    {
      path: "/apps",
      name: "apps",
      component: () => import("./views/static/Apps.vue"),
    },
    {
      path: "/app",
      name: "app",
      component: () => import("./views/static/Apps.vue"),
    },
    // {
    //   path: "/discount",
    //   name: "discount",
    //   component: () => import("./views/discount/index.vue"),
    // },
    // {
    //   path: "/discount-item",
    //   name: "discount-item",
    //   component: () => import("./views/discount/open.vue"),
    // },
    {
      path: "/blog",
      name: "blog",
      component: () => import("./views/blog/index.vue"),
    },
    {
      path: "/blog/:id",
      name: "blog-item",
      component: () => import("./views/blog/open.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
      meta: {
        onlyContent: true,
      },
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
