import ADD_ITEM_CART from "gql/mutations/CartItemManage.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";
let debounceTimeoutProduct = null;
let debounceTimeoutSet = null;
import ORDER_REPEAT from "@/graphql/mutations/purchase/OrderRepeat.graphql";

export async function confirmUser({ store, router, apollo, data = {}, callback }) {
  let guest = JSON.parse(JSON.stringify(store.state.auth.user));
  if (data.user) store.state.auth.user = data.user;
  store.state.auth_token = data.token;
  // Сохраняю токен в сессииx
  if (data.token) {
    require(["axios"], (axios) => {
      axios.default({
        method: "post",
        url: "/guest-token",
        data: {
          token: data.token,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    });
    setCookie("auth-token", data.token, 999);
    store
      .dispatch("USER", {
        apollo,
      })
      .then(() => {
        router.push({ name: "profile", params: { link: "profile" } }).catch(() => {
          router.push({ name: "home" });
        });
      });
  }
  // синхронизация данных
  if (guest) {
    store
      .dispatch("SYNC_USER_DATA", {
        apollo,
        user: guest,
      })
      .then(() => {
        store.dispatch("USER", {
          apollo,
        });
      });
  }
  if (callback) {
    callback();
  }
}

/**
 * Выход из аккаунта
 * @param store
 * @param apollo
 * @returns {Promise<void>}
 */
export async function logout({ store, router }) {
  store.state._modals.push({
    component: AlertModal,
    options: {
      title: "Выйти из аккаунта",
      message: "Вы уверены, что хотите выйти из аккаунта?",
      isConfirm: true,
      closeBtnTitle: "Нет",
      confirmBtnTitle: "Да",
      closeBtnClass: "btn--gray",
      callback: () => {
        store.state.auth_token = undefined;
        this.$cookie.delete("auth-token");
        store.state.auth.user = null;
        require(["axios"], (axios) => {
          axios.default({
            method: "post",
            url: "/guest-token",
            data: {
              token: null,
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
        }).then(() => {
          if (router.currentRoute.name === "profile") {
            router.push({ name: "home" }).then(() => {
              location.reload();
            });
          } else {
            location.reload();
          }
        });
      },
    },
  });
}

export async function addProductInCart({ store, apollo, product, count, step }) {
  if (count === 0) {
    store.state.auth.cart = store.state.auth.cart.filter((item) => {
      return item.product?.id !== product.id;
    });
  } else if (inCart({ store, data: product })) {
    // Если есть изменяем количество
    store.state.auth.cart.find((item) => item.product?.id === product.id).quantity = count / step;
  } else {
    // если товара нет, то добавляем
    store.state.auth.cart.unshift({
      quantity: count / step,
      product: product,
    });
  }
  clearTimeout(debounceTimeoutProduct);
  debounceTimeoutProduct = setTimeout(() => {
    let token = store.state.auth_token;
    apollo.mutate({
      mutation: ADD_ITEM_CART,
      variables: {
        product_id: product ? product.id : undefined,
        quantity: count ? count / step : 0,
      },
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    });
  }, 500);
}

export async function addSetInCart({ store, apollo, product, set, productSet, count, step }) {
  if (count === 0) {
    if (set) {
      // если количество 0 то удаляем его из массива
      store.state.auth.cart = store.state.auth.cart.filter((item) => {
        let set_id = set.product_set ? set.product_set.id : set.id;
        return item.product_set?.id !== set_id;
      });
    } else {
      productSet.set_items.find((item) => item.product?.id === product.id).quantity = 0;
    }
  } else {
    if (set) {
      // если набора нет, то добавляем
      store.state.auth.cart.unshift({
        quantity: count,
        product_set: {
          id: set.id,
          title: set.title,
        },
        set_items: set.products.map((item) => ({
          product: item,
          quantity: 1,
        })),
      });
    }
    if (productSet) {
      // если это продукт внутри набора то меняем его количество
      productSet.set_items.find((item) => item.product?.id === product.id).quantity = count / step;
    }
  }
  clearTimeout(debounceTimeoutSet);
  debounceTimeoutSet = setTimeout(() => {
    let token = store.state.auth_token;
    apollo.mutate({
      mutation: ADD_ITEM_CART,
      variables: {
        product_id: product ? product.id : undefined,
        quantity: count ? count / step : 0,
        product_set_id: set
          ? set.id || set.product_set.id
          : productSet
          ? productSet.product_set.id
          : undefined,
      },
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    });
  }, 500);
}

// export async function addItemsInCart({ store, apollo, product, set, productSet, count, step }) {
//   if (count === 0) {
//     // если количество 0 то удаляем его из массива
//     if (set) {
//       store.state.auth.cart = store.state.auth.cart.filter((item) => {
//         return item.product_set?.id !== set.product_set.id;
//       });
//     } else {
//       store.state.auth.cart = store.state.auth.cart.filter((item) => {
//         return item.product?.id !== product.id;
//       });
//     }
//   } else {
//     if (set) {
//       // если набора нет, то добавляем
//       store.state.auth.cart.unshift({
//         quantity: count,
//         product_set: {
//           id: set.id,
//           title: set.title,
//         },
//         set_items: set.products
//           .filter((item) => item.price)
//           .map((item) => ({
//             product: item,
//             quantity: item.price.step,
//           })),
//       });
//     } else {
//       if (productSet) {
//         // если это продукт внутри набора то меняем его количество
//         productSet.set_items.find((item) => item.product?.id === product.id).quantity = count / step;
//       } else {
//         // если это просто товар
//         // Проверка есть ли товар в корзине
//         if (inCart({ store, data: product })) {
//           // Если есть изменяем количество
//           store.state.auth.cart.find((item) => item.product?.id === product.id).quantity = count / step;
//         } else {
//           // если товара нет, то добавляем
//           store.state.auth.cart.unshift({
//             quantity: count / step,
//             product: product,
//           });
//         }
//       }
//     }
//   }
//   clearTimeout(debounceTimeoutSet);
//   debounceTimeoutSet = setTimeout(() => {
//     let token = store.state.auth_token;
//     apollo.mutate({
//       mutation: ADD_ITEM_CART,
//       variables: {
//         product_id: product ? product.id : undefined,
//         quantity: count ? count / step : 0,
//         product_set_id: set
//           ? set.id || set.product_set.id
//           : productSet
//           ? productSet.product_set.id
//           : undefined,
//       },
//       context: {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       },
//     });
//   }, 500);
// }

function inCart({ store, data }) {
  return data && store.state.auth.cart
    ? store.state.auth.cart.find((c) => c.product?.id === data?.id)
    : false;
}

/**
 * Проверка наличия скролла
 */
export function checkScroll() {
  const elem = this.$refs.scroll;
  if (elem && elem.scrollHeight > elem.clientHeight) {
    elem.classList.add("scroll");
  }
}

/**
 * Изменение выпадающих меню
 */
export function changeOverlay({ store, field, value }) {
  if (typeof value === "boolean") {
    store.state._overlays[field] = value;
  } else {
    store.state._overlays[field] = !store.state._overlays[field];
  }
  Object.keys(store.state._overlays).forEach((key) => {
    if (key !== field) {
      store.state._overlays[key] = false;
    }
  });
}

/**
 * Получение заголовка кнопки
 */
export function moreButtonTitle(total, first) {
  let diff = total - first;
  if (diff > 0) {
    return `Ещё ${diff} ` + this.$options.filters.plural_word(diff, "товар", "товара", "товаров");
  }
  return "Все товары";
}

/**
 * Парсинг ошибок валидации
 */
export function parseGqlErrors(graphQLErrors, form = "form") {
  graphQLErrors.forEach((err) => {
    if (err.extensions.category === "validation") {
      Object.keys(err.extensions.validation).forEach((key) => {
        if (this[form][key]) {
          this[form][key].errors.push(err.extensions.validation[key][0]);
        }
      });
    }
  });
}

/**
 * Сброс ошибок валидации
 */
export function resetErrors(form = "form") {
  Object.keys(this[form]).forEach((key) => {
    this[form][key].error = null;
    this[form][key].errors = [];
  });
}

/**
 * Добавление куки
 */
export function setCookie(name, value, days) {
  let expires = "";
  let sameSite = "; SameSite=Strict";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + sameSite + "; path=/";
}

/**
 * Получение куки
 */
export function getCookie(name) {
  let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

/**
 * Подтверждение повторения заказа
 */
export function confirmOrderRepeat(id) {
  this.$store.state._modals.push({
    component: AlertModal,
    options: {
      title: "Повторить заказ?",
      message:
        "Мы хотели бы предупредить о возможном отсутствии некоторых товаров из нашего списка. Мы приносим извинения за возможные неудобства и активно работаем над обновлением нашего ассортимента. Благодарим вас за понимание.",
      isConfirm: true,
      confirmBtnTitle: "Повторить",
      closeBtnTitle: "Отмена",
      closeBtnClass: "btn--gray",
      callback: () => orderRepeat({ order_id: id, apollo: this.$apollo, store: this.$store }),
    },
  });
}

/**
 * Повторения заказа
 */
export function orderRepeat({ order_id, apollo, store }) {
  let token = store.state.auth_token;
  apollo.provider.clients.purchase
    .mutate({
      mutation: ORDER_REPEAT,
      variables: {
        id: order_id,
      },
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      if (data.OrderRepeat) {
        store.state._modals.push({
          component: AlertModal,
          options: {
            message: "Товары добавлены в вашу корзину",
            title: "Готово",
          },
        });
        store.dispatch("GET_CART_ITEMS", {
          apollo: apollo.provider.clients,
        });
      }
    });
}

/**
 * Проверка на наличие svg в тексте
 */
export function containsSvg(text) {
  return text && text.includes("<svg");
}
