<template>
  <header class="header" :class="{ 'header-sticky': scrollingPage }">
    <!--    <div v-if="showHeaderBanner" class="header-banner" ref="headerBanner">-->
    <!--      <div class="header-banner__content container">-->
    <!--        <span class="header-banner__title">В приложении ещё удобнее совершать покупки</span>-->
    <!--        <div class="header-banner__buttons">-->
    <!--          <router-link to="" class="btn btn&#45;&#45;white btn&#45;&#45;sm">Подробнее</router-link>-->
    <!--          <div @click="hideBanner" class="header-banner__close">-->
    <!--            <RemixIconComponent category="system" name="close-circle-fill" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="header-top" ref="headerTop">
      <div class="container-padding">
        <div class="header-top__content">
          <div class="header-top__navigation">
            <a
              v-for="(link, i) in navigationLinks"
              :key="i"
              @click.prevent="goToLink(link)"
              class="header-top__link"
              :class="{ 'header-top__link-menu': link.menu }"
              href="#"
            >
              <div class="header-top__navigation-wrapper">
                <span>{{ link.title }}</span>
                <RemixIconComponent v-if="link.menu" category="system" name="arrow-drop-down-line" />
              </div>
              <TabComponent
                v-if="link.menu && toggleDropMenu"
                :data="dropMenuLinks"
                class="header-top__dropMenu"
              />
            </a>
          </div>
          <div class="header-top__purchase">
            <router-link
              v-for="(link, i) in header_documents"
              :key="i"
              :to="{ name: 'static', params: { id: link.id } }"
              class="header-top__link"
            >
              <span>{{ link.title }}</span>
            </router-link>
          </div>
          <div @click="qrActive = !qrActive" class="header-top__contact">
            <span>{{ contacts.title }}</span>
            <a :href="`tel:${contacts.phone}`" class="header-top__phone">{{ contacts.phone }}</a>
          </div>
          <!--        <TabComponent v-if="qrActive" qrActive>-->
          <!--          <IconComponent name="qr" />-->
          <!--          <span>Наведите камеру телефона на QR, чтобы начать звонок</span>-->
          <!--        </TabComponent>-->
        </div>
      </div>
    </div>
    <HeaderMainComponent :scrolling-page="scrollingPage" />
    <div v-if="$route.meta.showHotLinks" class="header-bottom">
      <div class="container-padding">
        <HotLinksComponent />
      </div>
    </div>
  </header>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import TabComponent from "components/TabComponent.vue";
import HeaderMainComponent from "components/header/components/HeaderMainComponent.vue";
import HotLinksComponent from "components/HotLinksComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HeaderComponent",
  components: { IconComponent, HotLinksComponent, HeaderMainComponent, TabComponent, RemixIconComponent },
  data() {
    return {
      scrollingPage: false,
      showHeaderBanner: true,
      toggleDropMenu: false,
      headerOffsetTop: null,
      qrActive: false,
      // links: [
      //   { title: "Новинки", link: "#", static: false },
      //   { title: "Бонусная карта", link: "#", static: false },
      //   { title: "Эко продукты", link: "#", static: false },
      //   { title: "Собственное производство", link: "#", static: false },
      //   { title: "Торты на заказ", link: "#", static: false },
      // ],
      dropMenuLinks: [
        { title: "Карта с магазинами" },
        { title: "Номера телефонов и почта" },
        { title: "Социальные сети" },
        { title: "Юридическое лицо" },
      ],
      navigationLinks: [
        { title: "Контакты и адреса", link: "about" },
        { title: "Блог", link: "blog" },
      ],
      purchaseLinks: [
        { link: "/static/documents/Оплата.pdf", title: "Оплата" },
        { link: "/static/documents/Доставка.pdf", title: "Доставка" },
        { link: "/static/documents/Обмен-и-возврат.pdf", title: "Обмен и возврат" },
      ],
      contacts: { title: "Горячая линия", phone: "+7 906 446 19 16" },
    };
  },
  mounted() {
    let el = document.getElementById("header-main");
    if (el) {
      this.headerOffsetTop = el.offsetTop;
      window.addEventListener("scroll", this.editHeader);
    }
  },
  computed: {
    links() {
      return this.$store.state.home_page.hot_links;
    },
    header_documents() {
      return this.$store.state.global.header_documents;
    },
  },
  methods: {
    // openCatalogModal() {
    //   this.$store.state._modals.push({
    //     component: CatalogModal,
    //     options: {},
    //   });
    // },
    goToLink({ link, menu }) {
      if (menu) {
        this.toggleDropMenu = !this.toggleDropMenu;
      } else {
        if (this.$route.name !== link) this.$router.push({ name: link });
      }
    },
    hideBanner() {
      this.showHeaderBanner = false;
      this.headerOffsetTop = this.headerOffsetTop - this.$refs.headerBanner.clientHeight;
    },
    editHeader() {
      let scrollPosition = window.scrollY;
      if (scrollPosition >= this.headerOffsetTop) {
        this.scrollingPage = true;
        this.toggleDropMenu = false;
      } else this.scrollingPage = false;
    },
  },
};
</script>

<style lang="stylus">
.header {
  //position absolute
  z-index 100
  width 100%
  //position: sticky;
  //top -125px
  //height 100%
}

.header-sticky {
  padding-top: 78px

  .header-main {
    filter: drop-shadow(0px 8px 24px rgba(149,157,165,0.2));
  }
}

.header-banner{
  background var(--green)
  padding: 16px

  &__content{
    display flex
    justify-content space-between
    align-items center
  }

  &__title{
    text-transform uppercase
    color var(--white)
    font-weight: 700;
    font-size: 1em
    line-height: 22px;
  }

  &__close{
    display flex
    align-items center
  }

  &__buttons{
    display flex
    align-items center
    gap 33px

    & .btn {
      padding: 9px
      color var(--gray-800)
      font-weight: 400;
      font-size: 0.750em
      line-height: 14px
    }

    & .icon{
      cursor: pointer;
      fill var(--white)
      width 20px
      height 20px
    }
  }
}

.header-top{
  background var(--white-4)
  +below(900px) {
    display none
  }

  &__content {
    padding: 16px 0
    font-weight: 400;
    font-size: 0.875em
    line-height: 20px;
    color var(--gray-700)
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 16px
    position: relative;
  }

  &__link{
    color var(--gray-700)

    &:hover {
      color var(--main)
    }
  }

  &__link-menu{
    position: relative;
    &:hover .header-top__dropMenu{
      opacity: 1;
      visibility visible
      transition var(--transition)
    }
  }

  &__dropMenu{
    position: absolute
    z-index 20
    top: 28px
    right -9px
    pointer-events auto
  }

  &__phone{
    line-height: 20px;
    cursor: pointer;
  }

  &__navigation{
    display flex
    gap 16px
    align-items center

    & .tab{
      width 213px
    }
  }

  &__navigation-wrapper{
    display flex
    align-items center

    & .icon{
      width 24px
      height 24px
      fill var(--gray-700)
    }
  }

  &__purchase{
    display flex
    gap 16px
    align-items: center;
    justify-content center
  }

  &__contact{
    display flex
    gap 8px
    align-items: center;
    justify-content flex-end
    cursor pointer

    & a {
      color: var(--gray-700)
    }
  }
}

.header-bottom {
  padding 16px 0
  position absolute
  width 100%
  +below(990px) {
    display none
  }
}
</style>
