<template>
  <div class="default-modal captcha-modal">
    <div class="captcha-modal__header default-modal__header">
      <span class="captcha-modal__title">Ввод капчи</span>
      <button
        type="button"
        class="default-modal__close"
        @click="$emit('close')"
        aria-label="Закрыть модальное окно"
      >
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="captcha-modal__body default-modal__body">
      <div class="captcha-modal__img">
        <img :src="captcha_img" alt="" />
        <button @click="reloadCaptcha" class="btn btn--md" title="Обновить фото">
          <RemixIconComponent category="device" name="restart-line" />
        </button>
      </div>
      <form @submit.prevent="submit" class="captcha-modal__form">
        <InputComponent
          v-model="form.captcha.value"
          placeholder="Введите число с изображения"
          mask="####"
          autofocus
        />
        <button class="btn btn--md btn--main" aria-label="Отправить">Отправить</button>
      </form>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "CaptchaModal",
  components: {
    InputComponent,
    RemixIconComponent,
  },
  props: {
    login: Function,
    set: Function,
  },
  data() {
    return {
      loading: false,
      captcha_img: null,
      form: {
        captcha: {
          value: null,
          errors: [],
        },
        captcha_key: {
          value: null,
          errors: [],
        },
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    submit() {
      this.set("captcha", this.form.captcha.value);
      this.set("captcha_key", this.form.captcha_key.value);
      this.login();
      this.$emit("close");
    },
    reloadCaptcha() {
      this.get();
    },
    get() {
      fetch(`${this.$store.state._env.HOST_ENDPOINT}/captcha/api`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        const data = await response.json();
        this.captcha_img = data.img;
        this.form.captcha_key.value = data.key;
      });
    },
  },
};
</script>

<style lang="stylus">
.captcha-modal {
  width 100%
  max-width 424px
  border-radius 16px
  padding: 33px 32px

  &__header {
    display flex
    justify-content space-between
    align-items center
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: var(--gray-900)
  }

	&__body {
		align-items stretch
		gap 15px
	}

  &__form {
    display flex
    gap 16px
		width 100%
		+below(410px) {
			flex-direction: column
		}

		.btn {
			flex-shrink 0
		}
  }

	&__img {
		position relative
		width 100%
		height: 108px

		img {
			border-radius 15px
		}

		.btn {
			position absolute
			right 5px
			top 5px
			width 35px
			height 35px
			background var(--gray-100)

			&:hover {
				background var(--main)
				color var(--white)

				.icon svg path {
					fill var(--white)
				}
			}
		}
	}
}
</style>
