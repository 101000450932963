<template>
  <div class="stars-rating">
    <span class="stars-rating__title">{{ title }}</span>
    <div class="stars-rating__body">
      <label
        @mouseover="hoverMark = item"
        @mouseleave="hoverMark = null"
        v-for="(item, i) in maxStars"
        :key="i"
        class="btn"
        :for="name + item"
      >
        <input
          :id="name + item"
          type="radio"
          :name="name"
          :value="item"
          :checked="modelValue === item"
          @change="handleChecked(item)"
        />
        <IconComponent
          class="stars-rating__item"
          :class="{
            'stars-rating__item--filled': hoverMark >= item || modelValue >= item,
            'stars-rating__item--opacity': hoverMark && item > hoverMark && item <= modelValue,
          }"
          name="star-fill"
        />
      </label>
    </div>
    <div class="stars-rating__errors">
      <span v-for="(item, i) in errors" :key="i">
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "StarsRatingComponent",
  emits: ["update:model-value"],

  /**
   * на компонент не получалось вешать v-model поэтому указал явно
   **/
  model: {
    prop: "modelValue",
    event: "update:model-value",
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: 0,
    },
    title: {
      type: String,
      default: "Выберите количество звёзд",
    },
    maxStars: {
      type: Array,
      default: () => [1, 2, 3, 4, 5],
    },
    name: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hoverMark: null,
    };
  },

  methods: {
    handleChecked(item) {
      this.$emit("update:model-value", item);
    },
  },

  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.stars-rating {
  display flex
  flex-direction column
  gap 16px
  width: fit-content;

  input[type='radio'] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  &__title {
    text-align center
    font-family: var(--font-regular);
    font-size: 0.875em
    font-style: normal;
    font-weight: 400;
  }

  &__body {
    display inline-flex
    align-items center
    justify-content flex-start
    gap 24px
  }

  &__item {
    width 40px !important
    height 40px !important

    &:not(&--filled) {
      svg path {
        fill var(--border-color-2)
      }
    }

    &--filled {
      svg path {
        fill var(--yelow-500)
      }
    }

    &--opacity {
      opacity 0.5
    }
  }

  &__errors {
    color var(--red)
    font-size 0.75rem
  }

}
</style>
