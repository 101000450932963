<template>
  <div class="product-gallery">
    <ProductParamSlider
      v-if="items && items.length > 1"
      v-slot="{ item }"
      :items="items"
      :slider-options="sliderOptions"
    >
      <label
        class="product-gallery__slider-img"
        :class="{ 'product-gallery__slider-img--active': active === item.id }"
      >
        <input type="radio" v-show="false" :value="item.id" v-model="active" />
        <ImgComponent :img="item" width="400" height="400" />
      </label>
    </ProductParamSlider>
    <div class="product-gallery__image">
      <div class="product-gallery__image-ratio">
        <div class="ratio-container">
          <ImgComponent :img="activeItem" width="800" height="800" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductParamSlider from "components/product/ProductParamSlider.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "ProductGalleryComponent",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    head_img: Object,
  },
  data() {
    return {
      active: null,
      sliderOptions: {
        direction: "horizontal",
        slidesPerView: 4,
        spaceBetween: 10,
        pagination: false,
        clickable: false,
        breakpoints: {
          // 1024: {
          //   direction: "vertical",
          //   slidesPerView: 4,
          // },
        },
      },
    };
  },
  computed: {
    activeItem() {
      return this.items.find((i) => i.id === this.active) || this.head_img;
    },
  },
  created() {
    if (this.items && this.items.length) {
      this.active = this.items[0].id;
    }
  },
  watch: {
    items() {
      if (this.items && this.items.length) {
        this.active = this.items[0].id;
      }
    },
  },
  components: { ProductParamSlider, ImgComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.product-gallery {
  display flex
  align-items flex-start
  flex-direction column-reverse
  gap 15px
  +below(1024px) {
    gap 0
    align-items: center;
  }

  .param-slider {
    width 323px
    flex-shrink: 0;
    height auto
  }

  &__slider-img {
    display flex
    align-items center;
    justify-content center
    background: var(--white);
    opacity: 0.3;
    border-radius: 8px;
    width: 64px;
    height: 64px;
    overflow hidden
    cursor pointer
    padding 5px
    transition  var(--transition)
    &:hover{
      opacity: 1
    }

    &--active {
      opacity: 1
    }

    img {
      max-width 100%
      max-height 100%
      object-fit contain
      object-position center
    }
  }

  &__image {
    width: 323px;
    max-width 100%
    height: 323px;
    display flex
    justify-content center
    align-items center
    overflow hidden
    //border: 1px solid var(--gray-dark);
    border-radius: var(--big-radius);
    //padding 20px

    &-ratio {
      ratio(1, 1, contain)
    }

    +below(1024px){
      width: 368px;
      height: 368px;
    }

    //img {
    //  max-height calc(100% - padding * 2)
    //  width 100%
    //  height 100%
    //  object-fit contain
    //  object-position center
    //}
  }
}
</style>
