<template>
  <div class="order-info-product">
    <ImgComponent :img="data.product.img" width="200" height="200" class="order-info-product__img" contain />
    <div class="order-info-product__info">
      <span class="order-info-product__status" :class="statusClass">{{ statusName }}</span>
      <span class="order-info-product__title">{{ data.product.title }}</span>
      <span class="order-info-product__weight">
        <b>{{ data.quantity }} {{ data.product.unit_type_id === types.UNIT_GRAM ? "г" : "шт" }}</b>
        <span>{{ pricePerQuant }}</span>
      </span>
      <span class="order-info-product__prices">
        <span class="order-info-product__price">{{ data.price | formatPrice }}</span>
        <span
          class="order-info-product__price-old"
          v-if="data.product.price && data.product.price.price_without_discount"
        >
          {{ (data.product.price.price_without_discount * data.quantity) | formatPrice }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "OrderInfoProductCard",
  components: { ImgComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statuses() {
      return this.$store.state._statuses;
    },
    types() {
      return this.$store.state._types;
    },
    statusClass() {
      switch (this.data.status_id) {
        case this.statuses.PRODUCT_WAIT: {
          return "orange";
        }
        case this.statuses.PRODUCT_ASSEMBLED: {
          return "yellow";
        }
        // case this.statuses.PRODUCT_CLARIFY: {
        //   return "blue";
        // }
        case this.statuses.PRODUCT_CANCELED: {
          return "red";
        }
      }
      return "green";
    },
    statusName() {
      switch (this.data.status_id) {
        case this.statuses.PRODUCT_WAIT: {
          return "Собираем";
        }
        case this.statuses.PRODUCT_ASSEMBLED: {
          return "Собран";
        }
        case this.statuses.PRODUCT_CLARIFY: {
          return "Уточнить";
        }
        case this.statuses.PRODUCT_CANCELED: {
          return "Отменен";
        }
      }
      return null;
    },
    pricePerQuant() {
      if (this.data.product.unit_type_id === this.types.UNIT_GRAM && this.data.product.price.price_per_kg) {
        return `${this.data.product.price.price_per_kg} ₽ за 1 кг`;
      }
      return `${this.data.product.price.value} ₽ за 1 шт`;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.order-info-product {
  display flex
  align-items center
  gap 12px
  border-radius: 16px;
  border: 1px solid var(--gray-light, #DDD);
  box-shadow: 2px 2px 5px 0 rgba(38, 51, 77, 0.03);
  padding 15px 19px
  +below(640px) {
    padding 10px 15px
  }
  +below(440px) {
    padding 8px 12px
  }

  &__img {
    width 80px
    height auto
    flex-shrink 0
    +below(440px) {
      width 70px
    }
  }

  &__info {
    display flex
    flex-direction column
    align-items flex-start
    gap 12px
    +below(640px) {
      gap 5px
    }
  }

  &__status {
    border-radius: 100px;
    background: var(--green-light-2, #EFF7EB);
    color: var(--green, #5AB033);
    font-size: 0.75rem
    font-weight: 500;
    line-height: normal;
    padding 4px 10px
  }

  &__title {
    font-size: 1.125rem
    font-weight: 500;
    line-height: 21px;
    +below(540px) {
      font-size: 1rem
      line-height normal
    }
    +below(440px) {
      font-size: 0.875rem
    }
  }

  &__weight {
    color: var(--gray-dark-2, #5A6171);
    font-size: 1.125rem
    line-height: 21px;
    display flex
    align-items baseline
    gap 8px
    +below(540px) {
      font-size: 1rem
      line-height normal
    }
  }

  &__prices {
    display flex
    align-items center
    gap 12px
  }

  &__price {
    color: var(--green, #5AB033);
    font-size: 1.25rem
    font-weight: 700;
    line-height: normal;
    +below(440px) {
      font-size: 1.125rem
    }

    &-old {
      color var(--gray-light-2, #999999)
      font-weight: 500;
      line-height: normal;
      text-decoration: line-through;
      +below(440px) {
        font-size: 0.875rem
      }
    }
  }
}
</style>
