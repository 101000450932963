function parent_categories(state) {
  state.global.parent_categories.forEach((item) => {
    item.child_categories = item.child_categories.filter(
      (child) => child.products && child.products.paginatorInfo && child.products.paginatorInfo.total
    );
  });
  return state.global.parent_categories.filter(
    (item) => item.child_categories && item.child_categories.length
  );
}

function total_set_price_cart(state) {
  return (items, without_sale) => {
    let setsInCart = items.filter((item) => item.product_set);
    let productsInSets = without_sale
      ? setsInCart.flatMap((item) => item.set_items).filter((p) => !p.product?.price?.price_without_discount)
      : setsInCart.flatMap((item) => item.set_items);
    if (state.promo_products.length && without_sale) {
      productsInSets = productsInSets.filter((item) => !state.promo_products.includes(item.product?.id));
    }
    let productWithPrice = productsInSets.filter((item) => item.product?.price);
    return productWithPrice
      ? productWithPrice.reduce((sum, item) => {
          return item.quantity * item.product?.price?.value + sum;
        }, 0)
      : null;
  };
}

function total_product_price_cart(state) {
  return (items, without_sale) => {
    let products = without_sale ? items.filter((p) => !p.product?.price?.price_without_discount) : items;
    if (state.promo_products.length && without_sale) {
      products = products.filter((item) => !state.promo_products.includes(item.product?.id));
    }
    return products
      ? products
          .filter((item) => !item.product_set && item.product?.price)
          .reduce((sum, item) => {
            return item.quantity * item.product?.price?.value + sum;
          }, 0)
      : null;
  };
}

export default Object.assign({
  parent_categories,
  total_set_price_cart,
  total_product_price_cart,
});
