<template>
  <div class="loading-logo">
    <figure class="icon">
      <svg width="150" height="150" viewBox="-69 -36 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100.291 40.4447C95.8901 40.4447 96.5675 40.9482 96.7357 36.7525C97.245 19.2984 109.257 5.03298 126.343 1.50861C129.048 1.00513 131.926 0.837311 134.631 0.837311C135.645 0.837311 135.818 1.34079 135.986 2.17993C136.496 21.8158 122.619 37.256 105.029 39.9412C103.506 40.2769 101.983 40.4447 100.291 40.4447Z"
          fill="#5AB033"
        />
        <path
          d="M106.005 62.545C93.6589 39.0109 66.0648 27.3284 40.5206 34.7781C9.32402 43.9209 -7.81753 77.4447 3.49656 108.429C13.9538 136.873 44.6391 151.603 73.4356 142.461C96.575 135.18 112.519 113.339 112.519 91.3283C112.519 79.6456 110.46 70.8415 106.005 62.545ZM65.8944 128.238C44.6391 133.317 23.5542 121.297 17.3812 100.471C11.0377 79.4766 22.8678 57.127 43.6071 50.3548C65.7193 43.0744 89.5452 56.1113 95.3774 78.6299C96.2342 82.0161 96.7454 85.5717 96.7454 88.9578C96.575 107.582 83.7177 124.006 65.8944 128.238Z"
          fill="#5AB033"
        />
        <path
          d="M77.5965 88.8553C77.7695 99.5339 69.1307 110.212 56.0907 110.212C44.4024 110.212 34.7487 100.535 34.9217 88.8553C35.0901 75.8407 45.5904 67.331 56.5958 67.4981C68.2841 67.4981 77.7695 77.3423 77.5965 88.8553Z"
          fill="#C61180"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.8s"
          values="0 0 0;360 0 0"
          keyTimes="0;1"
        ></animateTransform>
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  name: "LoadingLogoComponent",
};
</script>

<style lang="stylus">
.loading-logo {
  display: inline-flex
  align-items: center
	width: 100px
	height: 100px

	.icon {
		width: 100%
		height: 100%
	}
}
</style>
