<template>
  <div class="catalog-dropdown">
    <template v-if="categories && categories.length">
      <h3 class="catalog-dropdown__title">Каталог</h3>
      <div class="catalog-dropdown__body">
        <div class="catalog-dropdown__list">
          <a
            v-for="(category, i) in categories"
            :key="i"
            :class="{
              'catalog-dropdown__item--active': JSON.stringify(activeCategory) === JSON.stringify(category),
            }"
            class="catalog-dropdown__item"
            @mouseenter="setCategory(category)"
            @click.prevent="openCategory(category)"
            href="#"
          >
            <ImgComponent
              class="catalog-dropdown__item-img"
              :img="category.site_catalog_img"
              width="100"
              height="100"
            />
            <span class="catalog-dropdown__item-title" v-if="category">{{ category.title }}</span>
            <RemixIconComponent category="system" name="arrow-right-s-line" />
          </a>
        </div>
        <div class="catalog-dropdown__content">
          <h4 class="catalog-dropdown__content-title" v-if="activeCategory">
            <router-link :to="{ name: 'category-main', params: { link: activeCategory.link } }">
              {{ activeCategory.title }}
            </router-link>
          </h4>
          <div class="catalog-dropdown__cards">
            <router-link
              :to="{ name: 'category-open', params: { link: child.link } }"
              v-for="(child, i) in activeCategory.child_categories"
              :key="i"
              class="catalog-dropdown__card"
            >
              <ImgComponent
                :img="child.site_catalog_img"
                class="catalog-dropdown__card-img"
                width="100"
                height="100"
              />
              <span class="catalog-dropdown__card-title" v-if="child">{{ child.title }}</span>
            </router-link>
          </div>
          <router-link :to="{ name: 'catalog' }" class="btn btn--md btn--gray catalog-dropdown--open-all">
            Открыть полный каталог
          </router-link>
        </div>
      </div>
    </template>
    <IconComponent class="catalog-dropdown__loading" v-else name="loading" />
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CatalogComponent",
  data() {
    return {
      active: null,
    };
  },
  computed: {
    categories() {
      return this.$store.getters.parent_categories;
    },
    activeCategory() {
      return this.active || this.categories[0];
    },
  },
  methods: {
    setCategory(category) {
      this.active = category;
    },
    openCategory(category) {
      this.$router.push({ name: "category-main", params: { link: category.link } });
    },
  },
  components: {
    IconComponent,
    ImgComponent,
    RemixIconComponent,
    // HeaderMainComponent: () => import("components/header/components/HeaderMainComponent.vue"),
  },
};
</script>

<style lang="stylus">
.catalog-dropdown {
  padding 32px 40px
  display flex
  flex-direction column
  gap 24px
  height 100%
  +below(1025px) {
    display none
  }

  &__loading {
    width 40px
    height 40px
    margin auto
  }

  &__title {
    font-size 1.5em
    font-weight 500
    line-height: 36px;
    margin 0
  }

  &__body {
    display flex
    gap 24px
    height 100%
  }

  &__list {
    width 100%
    max-width 312px
    max-height 694px
    overflow-x auto
    display flex
    flex-direction column
    gap 8px
    padding-left 14px
    direction rtl
  }

  &__item {
    flex-shrink 0
    height 54px
    display flex
    align-items center
    padding 12px
    gap 4px
    border-radius 16px
    border: 1px solid var(--border-color-2);
    direction ltr
    color: var(--gray-800, #3F3B3B);
    transition var(--transition)

    &--active {
      background var(--gray-100)
    }

    &:hover {
      background var(--gray-100)
    }

    &-img {
      flex-shrink 0
      width 28px
      height: 28px
      border-radius 50%
      object-fit cover
      object-position center
      overflow hidden
    }

    &-title {
      flex-grow 1
      line-height 1.3
    }

    .icon {
      width 20px
      height 20px
    }
  }

  &__content {
    width 100%
    display flex
    flex-direction column
    gap 24px
  }

  &__content-title {
    font-size 1.25em
    line-height: 32px;
    font-weight 500
    margin 0

    a {
      color var(--body-color)
      transition var(--transition)

      &:hover {
        color var(--main)
      }
    }
  }

  &__cards {
    width 100%
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 24px
    overflow auto
    +below(1250px) {
      grid-template-columns 1fr 1fr
      grid-gap 16px
    }
  }

  &__card {
    width 100%
    height 76px
    display flex
    align-items center
    padding 8px 12px
    gap 8px
    border 1px solid var(--border-color-2)
    border-radius 16px
    cursor pointer
    color var(--gray-900)
    transition var(--transition)

    &:hover {
      background var(--gray-100)
      border-color var(--gray-100)
    }
  }

  &__card-img {
    flex-shrink 0
    width: 60px
    height: 60px
    border-radius 16px
    object-fit contain
    background var(--white)
  }

  &__card-title {
    font-weight 500
    line-height: 22px;
    word-break: break-word;
  }

  &--open-all {
    margin-top auto
  }
}
</style>
