<template>
  <label
    :class="{
      'radio--disabled': disabled,
      'radio--checked': checked,
      'radio--error': error,
    }"
    class="radio"
  >
    <span class="radio__state"></span>
    <input
      :checked="checked"
      :disabled="disabled"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
export default {
  name: "RadioComponent",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="stylus">
//@import "~@/styles/laravel-mix-fix.styl"
.radio {
  display flex
  gap 8px
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 0.875em; // 14px
  line-height: 18px;

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color var(--red) !important

    & ^[0]__state {
      border-color var(--red) !important
    }
  }

  &--disabled {
    color var(--gray)

    & ^[0]__state {
      border: 1px solid var(--gray);
    }

    &.radio--checked {
      & ^[0]__state {
        border: 1px solid var(--gray);

        &:after {
          background: var(--gray);
        }
      }
    }
  }

  &--checked:not(.radio--disabled) {
    color var(--dark)

    & ^[0]__state {
      border-color var(--main)

      &:after {
        background: var(--main);
      }
    }
  }

  &__state {
    width 16px
    height 16px
    background: var(--white);
    border: 1px solid var(--border-color-2);
    box-sizing: border-box;
    border-radius: 10px;
    display flex
    align-items center
    flex-shrink 0
    justify-content center
    transition .2s
    position relative

    &:after {
      content: "";
      width 10px
      height 10px
      flex-shrink 0
      border-radius 100%
      background transparent
      transition .2s
    }
  }
}
</style>
