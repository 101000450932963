<template>
  <div class="default-modal login-modal">
    <div class="login-modal__header">
      <span class="login-modal__title">Вход</span>
      <button
        type="button"
        class="default-modal__close"
        @click="$emit('close')"
        aria-label="Закрыть модальное окно"
      >
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="login-modal__body">
      <form @submit.prevent="submit" class="login-modal__form">
        <InputComponent
          v-model="form.phone.value"
          :errors="form.phone.errors"
          :disabled="step === 2"
          placeholder="Номер телефона"
          mask="+7 (###) ###-##-##"
          type="tel"
          is-phone
        />
        <template v-if="step === 2">
          <InputComponent
            v-model="form.code.value"
            :errors="form.code.errors"
            mask="####"
            placeholder="Введите код, который придет в смс"
            autofocus
          />
        </template>
        <template v-if="canSendCodeAgain && step === 2">
          <span @click="login" class="login-modal__code-again">Отправить код повторно</span>
        </template>
        <template v-else-if="step === 2">
          <div class="login-modal__code-wrapper">
            <span>Запросить новый код через</span>
            <span>00:{{ sendCodeTimer }}</span>
          </div>
        </template>
        <button class="btn btn--main login-modal__btn" :aria-label="step === 2 ? 'Вход' : 'Получить код'">
          <template v-if="step === 2"> Вход </template>
          <template v-else> Получить код </template>
        </button>
      </form>
    </div>
    <div class="login-modal__footer">
      <span class="login-modal__policy">
        Нажимая на кнопку, вы соглашаетесь на
        <router-link
          :to="{ name: 'static', query: { type: $store.state._types.ACT_PROCESSING_PERSONAL_DATA } }"
          target="_blank"
        >
          сбор и обработку персональных данных
        </router-link>
      </span>
      <!--      <span class="login-modal__or-line">или</span>-->
      <!--      <div class="login-modal__socials">-->
      <!--        <a class="login-modal__socials-item" v-for="(item, i) in socials" :key="i" :href="item.link">-->
      <!--          <IconComponent :name="item.icon" />-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import PHONE_CONFIRM from "@/graphql/mutations/UserPhoneConfirm.graphql";
import LOGIN from "@/graphql/mutations/UserLoginViaSite.graphql";
import SelectComponent from "components/inputs/select/index.vue";
import IconComponent from "components/IconComponent.vue";
import { confirmUser, parseGqlErrors } from "@/utils";
import CaptchaModal from "components/modals/components/CaptchaModal.vue";

export default {
  name: "LoginModal",
  components: {
    IconComponent,
    SelectComponent,
    InputComponent,
    RemixIconComponent,
  },
  data() {
    return {
      loading: false,
      sendCodeInterval: null,
      sendCodeTimer: null,
      canSendCodeAgain: false,
      step: 1,
      socials: [
        {
          title: "VK",
          link: this.$store.state._env.HOST_ENDPOINT + "/auth/vkontakte",
          icon: "vk",
        },
        {
          title: "Google",
          link: this.$store.state._env.HOST_ENDPOINT + "/auth/google",
          icon: "google",
        },
        {
          title: "Apple",
          link: this.$store.state._env.HOST_ENDPOINT + "/auth/apple",
          icon: "apple",
        },
      ],
      form: {
        phone: {
          value: null,
          errors: [],
        },
        code: {
          value: null,
          errors: [],
        },
        captcha: {
          value: null,
          errors: [],
        },
        captcha_key: {
          value: null,
          errors: [],
        },
      },
    };
  },
  watch: {
    "form.captcha.errors"(val) {
      if (val && val.length) {
        this.showCaptchaModal();
      }
    },
  },
  destroyed() {
    this.removeInterval();
  },
  methods: {
    confirmUser,
    parseGqlErrors,
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    submit() {
      if (this.step === 1) {
        this.showCaptchaModal();
      } else {
        this.confirmPhone();
      }
    },
    showCaptchaModal() {
      this.$store.state._modals.push({
        component: CaptchaModal,
        options: {
          login: this.login,
          set: (field, value) => {
            this.form[field].value = value;
          },
        },
      });
    },
    login() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors();
        this.$apollo.provider.clients.lk
          .mutate({
            mutation: LOGIN,
            variables: {
              phone: this.form.phone.value ? this.form.phone.value.replace(/[\s-()]/g, "") : null,
              captcha: this.form.captcha.value,
              captcha_key: this.form.captcha_key.value,
            },
          })
          .then(() => {
            this.loading = false;
            this.step = 2;
            this.removeInterval();
            this.startInterval();
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    confirmPhone() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors();
        this.$apollo.provider.clients.lk
          .mutate({
            mutation: PHONE_CONFIRM,
            variables: {
              code: this.form.code.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.removeInterval();
              this.confirmUser({
                store: this.$store,
                router: this.$router,
                apollo: this.$apollo.provider.clients,
                data: data.UserPhoneConfirm,
              });
            }
            this.$store.state._modals = [];
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    removeInterval() {
      clearInterval(this.sendCodeInterval);
    },
    startInterval() {
      this.removeInterval();
      this.sendCodeTimer = 59;
      this.canSendCodeAgain = false;
      this.sendCodeInterval = setInterval(() => {
        if (this.sendCodeTimer <= 1) {
          this.removeInterval();
          this.canSendCodeAgain = true;
        }
        this.sendCodeTimer = parseInt(this.sendCodeTimer) - 1;
        if (this.sendCodeTimer < 10) {
          this.sendCodeTimer = "0" + this.sendCodeTimer;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="stylus">
.login-modal{
  width 100%
  max-width 424px
  border-radius 16px
  padding: 33px 32px

  &__header{
    display flex
    justify-content space-between
    align-items center
  }

  &__title{
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: var(--gray-900)
  }

  &__body {
    width 100%
    padding-top: 17px;
  }

  &__form{
    display flex
    flex-direction column
    gap 16px
  }

  &__btn{
    width 100%
    height 46px
    border-radius: 16px !important
  }

  &__footer {
    display flex
    flex-direction column
    padding-top: 10px;
    gap: 32px
  }

  &__policy {
    font-size: 0.75em
    line-height: 120%;
    text-align: center;
    color: var(--body_muted)

    a {
      color: var(--body_muted)
      text-decoration underline

      &:hover {
        color var(--main)
      }
    }
  }

  &__code-wrapper {
    font-size: 0.875rem
    color var(--gray-700)
    text-align center
    line-height 6px
    display flex
    gap 4px
    justify-content center
    //text-decoration underline
    //cursor: pointer;
  }

  &__code-again {
    color var(--main)
    text-align center
    font-size: 0.875rem
    text-decoration underline
    cursor: pointer;
    line-height 6px
  }

  &__or-line {
    display grid
    grid-template-columns 1fr auto 1fr
    align-items center
    gap: 16px
    font-size: .875em
    line-height: 19px;

    &:before,
    &:after {
      content ""
      display block
      height 1px
      background var(--border-color-2)
    }
  }

  &__socials {
    display flex
    align-items center
    justify-content center
    gap: 32px

    &-item {
      display flex
      width 48px
      height: 48px

      .icon {
        width 100%
        height 100%
      }
    }
  }
}
</style>
