<template>
  <div class="map">
    <ClientOnly v-if="mapReady">
      <yandex-map :settings="settings" :coords="coords" zoom="14">
        <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
      </yandex-map>
    </ClientOnly>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import ClientOnly from "vue-client-only";

export default {
  name: "MapComponent",
  props: {
    address: String,
  },
  data() {
    return {
      mapReady: false,
      coords: [42.967631, 47.503442],
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/static/images/map-red-location.png",
        imageSize: [32, 36],
        imageOffset: [-18, -30],
      },
    };
  },
  mounted() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode(this.address).then((res) => {
          this.coords = res.geoObjects.get(0).geometry.getCoordinates();
          this.mapReady = true;
        });
      });
    });
    // let address = this.communications.find((item) => item.type.code === this.$store.state._types.ADDRESS);
    // if (address) {
    //   loadYmap({
    //     ...this.settings,
    //   }).then(() => {
    //     window.ymaps.ready(() => {
    //       window.ymaps.geocode(address).then((res) => {
    //         this.coords = res.geoObjects.get(0).geometry.getCoordinates();
    //         this.mapReady = true;
    //       });
    //     });
    //   });
    // }
  },
  components: {
    yandexMap,
    ymapMarker,
    ClientOnly,
  },
};
</script>

<style lang="stylus">
.map {
  width: 100%
  height: 400px

  .ymap-container {
    width 100%
    height 100%
  }
}
</style>
