<template>
  <div class="slider-arrows">
    <button
      type="button"
      title="Влево"
      class="slider-arrows__item slider-arrows__item--left"
      @click="$emit('left')"
      :disabled="isBeginning"
      v-if="!isBeginning"
    >
      <RemixIconComponent category="system" name="arrow-left-s-line" />
    </button>
    <button
      type="button"
      title="Вправо"
      class="slider-arrows__item slider-arrows__item--right"
      @click="$emit('right')"
      :disabled="isEnd"
      v-if="!isEnd"
    >
      <RemixIconComponent category="system" name="arrow-right-s-line" />
    </button>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "SliderArrowsComponent",
  props: {
    isBeginning: Boolean,
    isEnd: Boolean,
  },
  components: {
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.slider-arrows {
  z-index: 1;
  +below(860px) {
    display none
  }

  &__item {
    z-index: 1;
    margin auto
    background: var(--white);
    border: 1px solid var(--white-2);
    box-sizing: border-box;
    border-radius: 40px;
    width 36px
    height 36px
    display flex
    align-items center
    justify-content center
    cursor pointer
    transition var(--transition)

    &:not([disabled]):hover {
      border-color var(--main)

      .icon svg path {
        fill var(--main)
      }
    }

    &[disabled] {
      cursor default

      .icon svg path {
        fill var(--gray-100)
      }
    }

    .icon {
      width 16px
      height 16px

      svg path {
        transition var(--transition)
        fill var(--body-color-muted)
      }
    }

    &--left {
      absolute left -19px top bottom
      margin-left 0
      margin-right auto
      +below(1360px) {
        left: -19px
      }
    }

    &--right {
      absolute right -19px top bottom
      margin-left auto
      margin-right 0
      +below(1360px) {
        right -19px
      }
    }
  }
}
</style>
