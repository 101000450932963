<template>
  <div class="set-item">
    <div class="set-item__header">
      <span class="set-item__title"> {{ set.product_set.title }} </span>
      <button
        @click="removalCancel"
        class="btn btn--cart-delete set-item__cancel"
        :class="{ 'set-item__cancel-animated': removal }"
      >
        Отмена
      </button>
      <div v-if="!removal" class="set-item__wrapper">
        <button @click="removeSet(set)" class="set-item__remove btn">
          <RemixIconComponent category="system" name="close-fill" />
          <span>Удалить набор</span>
        </button>
        <div
          @click="dropSet(index)"
          class="set-item__down"
          :class="{ 'set-item__down-reverse': activeSet === index }"
        >
          <RemixIconComponent category="system" name="arrow-down-s-line" />
        </div>
      </div>
    </div>
    <div v-show="activeSet === index" class="set-item__product">
      <CartItemComponent
        v-for="(item, i) in set.set_items"
        :key="'set-product' + i"
        :set="set"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CartItemComponent from "components/CartItemComponent.vue";
import { addSetInCart } from "../utils";

export default {
  name: "SetItemComponent",
  components: { CartItemComponent, RemixIconComponent },
  props: {
    index: Number,
    set: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      removal: false,
      showSetProduct: false,
      activeSet: null,
    };
  },
  methods: {
    addSetInCart,
    dropSet(i) {
      if (this.activeSet === i) {
        this.activeSet = null;
      } else this.activeSet = i;
    },
    removalCancel() {
      this.removal = false;
      clearTimeout(this.debounce);
    },
    removeSet(set) {
      this.removal = true;
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.addSetInCart({
          store: this.$store,
          apollo: this.$apollo.provider.clients.purchase,
          set: set,
          count: 0,
        });
        this.removal = false;
      }, 2500);
    },
  },
};
</script>

<style lang="stylus">
.set-item {
  width 100%
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 16px
  padding-left 24px

  &__header {
    display flex
    justify-content space-between
    align-items center

    & .icon svg {
      height 16px
      width 16px
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.250em
    line-height: 24px;
    color var(--gray-900)
    flex-shrink 0

    +below(628px) {
      font-size: 1rem
    }

    +below(428px) {
      flex-shrink 1
    }
  }

  &__wrapper {
    display flex
    gap 21px
    align-items center
    flex-shrink 0

    +below(428px) {
      gap 8px
    }
  }

  &__cancel {
    visibility hidden
    flex-shrink 10

    &::before {
      visibility hidden
      content: ""
      position absolute
      left 0
      z-index -1
      width 100%
      background var(--red-50)
      height 100%
      transition 2.5s width linear
    }
  }

  &__cancel-animated {
    visibility visible

    &::before {
      visibility visible
      width 0
    }
  }

  &__remove {
    display flex
    gap 4px
    align-items center

    & a {
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color var(--gray-800)
    }
  }

  &__down {
    display flex
    align-items center
    justify-content center
    padding: 9px
    background: var(--gray-100)
    border: 1px solid var(--gray-100)
    border-radius: 12px;
  }

  &__down-reverse {
    & .icon {
      transform rotate(180deg)
    }
  }

  &__product {
    display flex
    flex-direction column
    gap 16px
    margin-top 16px;
  }
}
</style>
