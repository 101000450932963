<template>
  <div class="default-modal cart-clear-modal">
    <div class="default-modal__header cart-clear-modal__header">
      <h3 class="default-modal__title">Очистить корзину</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body cart-clear-modal__body">
      <div class="cart-clear-modal__message cart-clear-modal__message-bold">
        Удалить все товары из корзины?
      </div>
      <div class="cart-clear-modal__message">
        Все товары будут удалены из корзины. <br />
        Отменить удаление нельзя
      </div>
    </div>
    <div class="default-modal__footer cart-clear-modal__footer">
      <button type="button" class="btn btn--md btn--gray" @click="$emit('closeAll')">Не удалять</button>
      <button type="button" class="btn btn--md btn--main" @click="clear">Удалить</button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CART_DELETE from "gql/mutations/purchase/CartDelete.graphql";

export default {
  name: "CartClearModal",
  components: { RemixIconComponent },
  methods: {
    clear() {
      let token = this.$store.state.auth_token;
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: CART_DELETE,
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(() => {
          this.$store.state._modals = [];
          this.$store.state.auth.cart = [];
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>

<style lang="stylus">
.cart-clear-modal {
  width 100%
  max-width 424px
  padding 32px

  &--center &__header {
    justify-content center
  }

  &__message {
    width 100%
    font-size: 1rem
    line-height: 140%;
    //text-align center
    //white-space pre-line

    +below(400px) {
      font-size: 0.875rem
    }

    &-bold {
      font-weight 500
      font-size: 1.25rem
      line-height: 32px;
      margin-bottom: 8px

      +below(400px) {
        font-size: 1rem
        line-height 20px
      }
    }
  }

  &__body {
    padding-top: 20px
  }

  &__footer {
    flex-direction row
    gap 24px !important
    padding-top: 30px !important

    .btn {
      width 100%
      justify-content center
    }
  }
}
</style>
