<template>
  <div class="default-modal alert-modal" :class="{ 'alert-modal--center': center }" :id="modalId">
    <div class="default-modal__header alert-modal__header">
      <h3 class="default-modal__title" v-if="title">{{ title }}</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body">
      <div class="alert-modal__message">
        {{ message || "Успешно!" }}
      </div>
    </div>
    <div class="default-modal__footer alert-modal__footer">
      <button type="button" :class="`btn btn--md ${closeBtnClass}`" @click="$emit('closeAll')">
        {{ closeBtnTitle }}
      </button>
      <button type="button" :class="`btn btn--md ${confirmBtnClass}`" @click="confirm()" v-if="isConfirm">
        {{ confirmBtnTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "AlertModal",
  props: {
    title: String,
    message: String,
    isConfirm: Boolean,
    center: Boolean,
    closeBtnTitle: {
      type: String,
      default: "Закрыть",
    },
    confirmBtnTitle: {
      type: String,
      default: "Подтвердить",
    },
    closeBtnClass: {
      type: String,
      default: "btn--main",
    },
    confirmBtnClass: {
      type: String,
      default: "btn--main",
    },
    callback: Function,
    args: {
      type: Array,
      default: () => [],
    },
    modalId: String,
  },
  methods: {
    confirm() {
      if (this.callback) {
        this.callback(...this.args);
      }
      this.$emit("close");
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.alert-modal {
  width 100%
  max-width 382px
  padding 20px

  &--center &__header {
    justify-content center
  }

  &__message {
    width 100%
    text-align center
    white-space pre-line
  }

  & &__footer {
    flex-direction row

    .btn {
      width 100%
      justify-content center
    }
  }
}
</style>
