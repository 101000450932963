<template>
  <div class="shops-component">
    <div class="shops-component__shops">
      <label v-for="(shop, i) in shops" :key="i" class="shops-component__shop">
        <div class="shops-component__shop-left">
          <RadioComponent
            :checked="
              JSON.stringify(active) === JSON.stringify(shop) &&
              client_delivery_info.type_id === types.RECEIVE_PICKUP
            "
            @change="handleRadioSelect(shop)"
          />
          <div class="shops-component__shop-text">
            <span class="shops-component__shop-address">{{ shop.title }}</span>
            <span class="shops-component__shop-schedule">{{ shop.address }}</span>
          </div>
        </div>
        <!--        <a class="btn btn&#45;&#45;md shops-component__btn">-->
        <!--          <RemixIconComponent category="map" name="map-pin-2-line" />-->
        <!--          <span>Посмотреть на карте</span>-->
        <!--        </a>-->
      </label>
    </div>
  </div>
</template>

<script>
import RadioComponent from "components/inputs/RadioComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import CHOOSE_SHOP from "@/graphql/mutations/DeliveryChooseShop.graphql";

export default {
  name: "ShopsComponent",
  data() {
    return {
      active: undefined,
    };
  },
  computed: {
    shops() {
      return this.$store.state.global.shops;
    },
    token() {
      return this.$store.state.auth_token;
    },
    client_delivery_info() {
      return this.$store.state.auth.client_delivery_info;
    },
    types() {
      return this.$store.state._types;
    },
  },
  mounted() {
    if (this.client_delivery_info && this.client_delivery_info.shop) {
      this.active = this.shops.find((i) => i.id === this.client_delivery_info.shop.id);
    }
  },
  methods: {
    handleRadioSelect(shop) {
      this.active = shop;
      this.chooseShop(shop);
    },
    chooseShop(shop) {
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: CHOOSE_SHOP,
          variables: {
            shop_id: shop.id,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          if (data.DeliveryChooseShop) {
            location.reload();
            // this.$store.dispatch("GET_DELIVERY_INFO", {
            //   apollo: this.$apollo.provider.clients,
            //   token: this.token,
            // });
          }
        });
    },
  },
  components: { RadioComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.shops-component {
  width 100%

  &__cities {
    display flex
    flex-direction column
    gap 32px
  }

  &__city {
    display flex
    flex-direction column
    gap 16px
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 32px
    color var(--gray-900)
    margin-bottom 0
  }

  &__shops {
    display flex
    flex-direction column
    gap 8px
  }

  &__shop {
    display flex
    justify-content space-between
    border 1px solid var(--border-color-2)
    border-radius 16px
    padding 16px
    cursor pointer
    transition var(--transition)

    &:hover {
      border-color var(--main)
    }
  }

  &__shop-left {
    display flex
    align-items flex-start
    gap 8px
  }

  &__shop-text {
    display flex
    flex-direction column
  }

  &__shop-address {
    font-size .875em
    font-weight 600
    line-height 140%
    color var(--gray-900)
  }

  &__shop-schedule {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__btn {
    gap 6px
    font-size .875em
    line-height 140%

    .icon {
      width 16px
      height 16px
    }
  }
}
</style>
