<template>
  <div class="default-modal product-review-modal">
    <div class="product-review-modal__header">
      <div class="product-review-modal__header-article">
        <span>Оценить товар</span>
      </div>
      <button class="btn btn--gray" type="button" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="product-review-modal__body">
      <StarsRatingComponent
        v-model="form.main_mark.value"
        :errors="form.main_mark.errors"
        name="main"
        title="Оценка заказа"
      />
      <TextareaComponent v-model="form.comment.value" placeholder="Комментарий" />
    </div>
    <div class="product-review-modal__footer">
      <button v-if="saving" class="btn btn--gray btn--md">
        <LoadingIndicator title="" />
      </button>
      <button v-else class="btn btn--main btn--md" @click="submit">Готово</button>
    </div>
  </div>
</template>

<script>
import TextareaComponent from "components/TextareaComponent.vue";
import StarsRatingComponent from "components/inputs/StarsRatingComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import LoadingIndicator from "components/Loadingindicator.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import PRODUCT_REVIEW_CREATE from "@/graphql/mutations/purchase/ProductReviewCreate.graphql";
import dayjs from "dayjs";

export default {
  name: "ReviewModal",
  emits: ["close"],
  props: {
    id: Number,
    callback: Function,
  },

  mounted() {},

  data() {
    return {
      saving: false,
      form: {
        main_mark: {
          value: null,
          errors: [],
        },
        comment: {
          value: null,
        },
      },
    };
  },

  methods: {
    collectData() {
      const variables = {
        product_id: this.id,
      };

      Object.keys(this.form).forEach((key) => {
        variables[key] = this.form[key].value ?? undefined;
      });

      return variables;
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    submit() {
      this.resetErrors();
      this.saving = true;
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: PRODUCT_REVIEW_CREATE,
          variables: this.collectData(),
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(() => {
          this.$emit("closeAll");
          this.$store.state._modals.push({
            component: AlertModal,
            options: {
              title: "Отзыв успешно отправлен!",
              message: "Спасибо за ваш отзыв! Мы ценим ваше мнение и будем стараться становиться еще лучше..",
            },
          });
        })
        .catch(({ graphQLErrors }) => {
          graphQLErrors.forEach((err) => {
            if (err.extensions.category === "validation") {
              Object.keys(err.extensions.validation).forEach((key) => {
                if (key === "product_id") {
                  this.$store.state._modals.push({
                    component: AlertModal,
                    options: {
                      title: "Оставить отзыв",
                      message: err.extensions.validation[key][0],
                    },
                  });
                }
                if (key === "main_mark") {
                  this.form.main_mark.errors = ["Оцените товар что бы оставить отзыв"];
                }
              });
            }
          });
          // this.$emit("closeAll");
          // this.$store.state._modals.push({
          //   component: AlertModal,
          //   options: {
          //     title: "Вы уже оставили отзыв!",
          //     message: "Мы ценим ваше мнение и будем стараться становиться еще лучше.",
          //   },
          // });
        })
        .finally(() => {
          if (this.callback) {
            this.callback();
          }
          this.saving = false;
        });
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
  },
  components: {
    TextareaComponent,
    StarsRatingComponent,
    RemixIconComponent,
    LoadingIndicator,
  },
};
</script>

<style lang="stylus">
.product-review-modal {
  width 100%
  max-width 424px;
  padding 32px
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center
    font-size: 1.500rem;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }

  &__body {
    display flex
    flex-direction column
    align-items center
    gap 16px

    .textarea {
      width: 100%;
    }
  }

  &__footer {
    width 100%

    .btn {
      width 100%
    }
  }
}
</style>
