<template>
  <div class="order-info default-modal">
    <IconComponent class="order-info__loading" name="loading" v-if="loading" />
    <template v-else-if="data">
      <div class="order-info__header default-modal__header">
        <span class="order-info__number">№ {{ data.id }}</span>
        <span class="order-info__status" :class="{ 'order-info__status--paid': data.paid }">
          {{ data.paid ? "Оплачен" : "Не оплачен" }}
        </span>
        <span class="order-info__sum">{{ data.amount | formatPrice }}</span>
      </div>
      <div class="order-info__body default-modal__body">
        <div v-if="items && items.length" class="order-info__items">
          <OrderInfoProductCard v-for="(item, i) in items" :key="i" :data="item" />
        </div>
        <span v-else>Товары отсутствуют</span>
      </div>
    </template>
    <span v-else>Информация отсутствует</span>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ORDER_ITEM from "@/graphql/queries/purchase/guest_orders_item.graphql";
import OrderInfoProductCard from "components/product/OrderInfoProductCard.vue";

export default {
  name: "OrderInfoModal",
  props: {
    id: Number,
  },
  components: {
    OrderInfoProductCard,
    IconComponent,
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  async created() {
    this.loading = true;
    await this.$apollo.provider.clients.purchase
      .query({
        query: ORDER_ITEM,
        variables: {
          id: this.id,
        },
      })
      .then(({ data }) => {
        this.data = data.guest_orders_item;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    items() {
      if (this.data && this.data.items && this.data.items.length) {
        return this.data.items.filter((i) => i.status_id === this.$store.state._statuses.PRODUCT_ASSEMBLED);
      }
      return [];
    },
  },
};
</script>

<style lang="stylus">
.order-info {
  display flex
  flex-direction column
  gap 24px
  padding 40px
  background-color var(--white)
  width 100%
  max-width: 640px;
  +below(640px) {
    padding 30px
  }
  +below(440px) {
    padding 24px
  }

  &__header {
    padding 0
    display flex
    align-items center
    justify-content stretch
  }

  &__number {
    font-size: 1.75rem
    font-weight: 700;
    line-height: normal;
    +below(540px) {
      font-size: 1.5rem
    }
  }

  &__status {
    padding 10px 15px
    border-radius: 8px;
    background: var(--border-color-2, #ECECED);
    font-size: 0.875rem
    font-weight: 500;
    line-height: normal;
    +below(540px) {
      padding 8px 10px
    }

    &--paid {
      background: var(--green-light-2, #EFF7EB);
      color var(--green, #5AB033)
    }
  }

  &__sum {
    margin-left auto
    padding 3px 11px
    text-align: center;
    font-size: 1.5rem
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--gray-light, #DDD);
    +below(540px) {
      font-size: 1.25rem
    }
  }

  &__loading {
    width: 60px
    height: 60px
    margin auto
    +below(440px) {
      width: 60px
      height 60px
    }
  }

  &__items {
    display flex
    flex-direction column
    gap 12px
    width 100%
  }
}
</style>
