<template>
  <div v-if="links && links.length" class="hot-links">
    <!--    <router-link :to="{ name: 'discount' }" class="hot-links__item hot-links__item&#45;&#45;highlight">-->
    <!--      % Скидки со вкусом-->
    <!--    </router-link>-->
    <router-link
      :to="{ name: 'category-open', query: { discount: true } }"
      class="hot-links__item hot-links__item--highlight"
    >
      Акции
    </router-link>
    <a v-for="(link, i) in links" :key="i" :href="link.link" target="_blank" class="hot-links__item">
      {{ link.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: "HotLinksComponent",
  props: {
    list: Array,
  },
  computed: {
    links() {
      return this.list || this.$store.state.home_page.hot_links;
    },
  },
};
</script>

<style lang="stylus">
.hot-links {
  display flex
  gap: 8px
  width: 100%
  overflow auto

  &__item {
    flex-shrink 0
    padding 7px 15px
    background var(--white)
    border: 1px solid var(--border-color-2);
    border-radius: 12px;
    font-size: 0.875em
    line-height: 14px;
    color var(--gray-900)

    &:hover {
      background var(--green-50)
      border-color var(--green)
    }

    &--highlight {
      background var(--rose-50)
      border-color var(--rose-50)
      color var(--rose-800)
    }
  }
}
</style>
