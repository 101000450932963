<template>
  <div v-if="count && count > 0">
    <button v-if="isSet" @click="change(0)" class="btn btn--sm btn--main-hollow product-count__btn-set">
      В корзине
    </button>
    <template v-else>
      <div class="product-count" :class="{ 'product-count--cart': isCart }">
        <div class="product-count__btn" @click.stop="change(count - step)">
          <RemixIconComponent category="system" name="subtract-line" />
        </div>
        <InputComponent
          v-show="focused"
          v-model.number="modelCount"
          @blur="change(modelCount)"
          @keydown="change(modelCount)"
          mask="######"
          ref="input"
          type="number"
          class="product-count__input"
        />
        <span v-show="!focused" @click.prevent.stop="inputFocus()" class="product-count__title">
          {{ modelCount }} {{ quant }}
        </span>
        <div class="product-count__btn" @click.s.stop="change(count + step)">
          <RemixIconComponent category="system" name="add-line" />
        </div>
      </div>
    </template>
  </div>
  <button
    v-else-if="isModal"
    @click.stop="change(step)"
    class="btn btn--main btn--sm"
    :aria-label="`Добавить в корзину ${data.title}`"
  >
    <RemixIconComponent category="finance" name="shopping-cart-line" />
    Купить
  </button>
  <button
    v-else
    @click.stop="change(step)"
    class="btn btn--sm btn--main-hollow"
    type="button"
    :aria-label="`Добавить в корзину ${data.title}`"
  >
    <RemixIconComponent category="finance" name="shopping-cart-line" />
  </button>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import { addProductInCart, addSetInCart } from "../../utils";
import AlertModal from "components/modals/components/AlertModal.vue";
import DeliveryModal from "components/modals/components/DeliveryModal.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ProductCountChangeComponent",
  components: { InputComponent, RemixIconComponent },
  props: {
    isCart: {
      type: Boolean,
      default: false,
    },
    isSet: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    set: Object,
  },
  data() {
    return {
      count: 0,
      modelCount: 0,
      focused: false,
    };
  },
  mounted() {
    this.count = this.inCartCount * this.step;
    this.modelCount = this.inCartCount * this.step;
    this.$emit("update:count", this.inCartCount);
  },
  methods: {
    addSetInCart,
    addProductInCart,
    inputFocus() {
      this.focused = true;
      this.$nextTick(() => {
        this.$refs.input.$refs.input.focus();
      });
    },
    change(val) {
      this.focused = false;
      if (val <= 0 && this.isCart) {
        this.$emit("remove");
      } else {
        if (this.$store.state.auth.client_delivery_info?.shop) {
          // округляем значение чтобы оно было кратно шагу
          let correctQuantity = Math.ceil(val / this.step) * this.step;
          this.count = correctQuantity;
          this.modelCount = correctQuantity;
          if (this.isSet || this.set) {
            this.addSetInCart({
              store: this.$store,
              apollo: this.$apollo.provider.clients.purchase,
              product: !this.isSet ? this.data : undefined,
              productSet: this.set ? this.set : undefined,
              set: this.isSet ? this.data : undefined,
              count: this.count ? parseInt(this.count) : 0,
              step: this.step,
            });
          } else {
            this.addProductInCart({
              store: this.$store,
              apollo: this.$apollo.provider.clients.purchase,
              product: this.data,
              count: this.count ? parseInt(this.count) : 0,
              step: this.step,
            });
          }
        } else {
          this.$store.state._modals.push({
            component: AlertModal,
            options: {
              title: "Выберите адрес или магазин",
              message: "Для добавления товаров в корзину необходимо выбрать адрес или магазин",
              isConfirm: true,
              closeBtnTitle: "Закрыть",
              confirmBtnTitle: "Выбрать",
              closeBtnClass: "btn--gray",
              callback: () => {
                this.$store.state._modals.push({
                  component: DeliveryModal,
                });
              },
            },
          });
        }
      }
    },
  },
  watch: {
    inCartCount(newValue) {
      this.count = newValue * this.step;
      this.modelCount = newValue * this.step;
      this.$emit("update:count", newValue);
    },
  },
  computed: {
    cartItems() {
      return this.$store.state.auth.cart;
    },
    // проверка есть ли этот продукт в корзине
    itemInCart() {
      // если это продукт внутри набора то ищем его среди массива продуктов в наборе
      if (this.set) {
        return this.set ? this.set.set_items.find((item) => item.product.id === this.data.id) : {};
      } else {
        // если обычный продукт то ищем среди продуктов
        return this.$store.state.auth.cart
          ? this.$store.state.auth.cart.find((item) => {
              if (this.isSet) {
                return item.product_set?.id === this.data.id;
              }
              return item.product?.id === this.data.id;
            })
          : {};
      }
    },
    // количество товара в корзине
    inCartCount() {
      return this.itemInCart ? this.itemInCart.quantity : 0;
    },
    step() {
      if (this.isSet) {
        return 1;
      }
      return this.data.price?.step;
    },
    quant() {
      return this.data.unit_type_id === this.$store.state._types.UNIT_GRAM ? "г" : "шт";
    },
  },
};
</script>

<style lang="stylus">
.product-count {
  max-width 105px
  height 38px
  padding: 10px
  border-radius 12px
  border 1px solid var(--green)
  display flex
  align-items center

  ^[0]--cart {
    height auto
    padding: 13px 12px
    background: var(--white)
    border: 1px solid var(--border-color)
    border-radius: 16px;
    max-width: 120px;
  }

  //&__type {
  //  position: absolute;
  //  right: 63px;
  //  bottom: 35px;
  //  font-size: 10px;
  //}

  & .icon {
    width 16px
    height 16px
    cursor pointer

    ^[0]--cart & {
      width 20px
      height 20px
    }

    & svg {
      fill var(--green)
    }
  }

  &__title {
		flex-grow: 1
    font-weight: 400;
    font-size: 0.75rem
    line-height: 120%;
    color: var(--gray-900)
    width 50px
    text-align center

    ^[0]--cart & {
      line-height 140%
      font-size: 0.875rem
      text-align center
      width 63px
    }
  }

  &__btn {
    display flex
    align-items center
		flex-shrink: 0
  }

  &__input {
    //opacity 0
    width: 50px
		flex-grow: 1

    ^[0]--cart & {
      max-width 63px
    }

    & .input {
      &__container {
        padding: 0;
        height: 34px;
        border: none;
        background none;
        border-radius: 0;

        ^[0]--cart & {
          height auto
        }
      }

      &__field {
        text-align center
        height 36px
        font-weight: 400;
        font-size: 0.75rem
        line-height: 120%;
        color: var(--gray-900)

        ^[0]--cart & {
          height auto
          line-height 140%
          font-size: 0.875rem
        }
      }
    }
  }

  &__btn-set {
    font-size: 0.875rem !important
    background var(--main) !important
    color var(--white) !important
    transition var(--transition)

    &:hover {
      border-color var(--red) !important
      background var(--red) !important
    }
  }
}
</style>
