<template>
  <div :class="{ 'tab--right': qrActive }" class="tab">
    <RemixIconComponent category="system" name="arrow-up-s-fill" />
    <div class="tab__list" :class="{ tab__qr: qrActive }">
      <slot />
      <div class="tab__item" v-for="(item, i) in data" :key="i">
        <span class="tab__title">
          {{ item.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "TabComponent",
  components: { RemixIconComponent },
  props: {
    qrActive: {
      type: Boolean,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.tab{
  position: relative;
  filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2));

  &--right {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
  }
  & .icon{
    position: absolute;
    width 24px
    height 24px
    right: 9px;
    top: -13px;
    fill: var(--white);
  }

  &__list{
    background var(--white)
    padding: 4px 8px
    border-radius: 8px;
  }

  &__item{
    padding: 7px
    border-radius: 8px;
    &:hover{
      background var(--gray-100)

      & span{
        color var(--gray-900)
      }
    }
  }

  &__title{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color var(--gray-700)
  }
  &__qr {
    width: 220px;
    padding 16px
    display flex
    flex-direction column
    align-items center
    gap 10px
  }
  .qr-icon {
    width: 120px;
    height: 120px;
    position: inherit;
  }
}
</style>
