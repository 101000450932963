<template>
  <div class="alert">
    <span class="alert__title">{{ title }}</span>
    <span class="alert__description">{{ description }}</span>
    <button
      @click="$emit('close')"
      type="button"
      class="alert__close-btn btn"
      aria-label="Закрыть модальное окно"
    >
      <RemixIconComponent category="system" name="close-fill" />
    </button>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "AlertComponent",
  components: { RemixIconComponent },
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="stylus">
.alert {
  position absolute
  top 50%
  left 50%
  right 0
  transform translate(-50%, -50%)
  z-index 1
  background var(--white)
  border-radius var(--big-radius)
  display flex
  flex-direction column
  align-items center
  padding 20px

  &__title {
    color var(--red)
  }

  &__close-btn.btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;

    &:hover {
      background: var(--gray-100);
    }
  }
}
</style>
