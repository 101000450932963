<template>
  <div class="dropdown" :class="{ 'dropdown--visible': show }">
    <CatalogComponent v-show="overlays.catalogOverlay" />
    <SearchComponent v-show="overlays.searchOverlay" />
  </div>
</template>

<script>
import CatalogComponent from "components/dropdown/CatalogComponent.vue";
import SearchComponent from "components/dropdown/SearchComponent.vue";

export default {
  name: "DropdownComponent",
  props: {
    title: String,
  },
  components: { SearchComponent, CatalogComponent },
  computed: {
    overlays() {
      return this.$store.state._overlays;
    },
    show() {
      return Object.values(this.overlays).some((k) => k);
    },
  },
};
</script>

<style lang="stylus">
.dropdown {
  position: absolute;
  left 0
  right 0
  top 100%
  z-index 1
  visibility hidden
  opacity: 0;
  transition var(--transition)
  background var(--white)
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 32px 32px;
  overflow hidden
  max-height calc(100vh - 150px)
  display flex
  flex-direction column

  &--visible {
    visibility visible
    opacity 1
  }
}
</style>
